import { z } from "zod";

export const VehicleAlertSchema = z.object({
  alertId: z.number(),
  setId: z.string(),
  vehicleId: z.string(),
  type: z.string(),
  // state is returned as a string, but we know some of the possible values
  state: z.union([
    z.literal("PENDING"),
    z.literal("DELAYED"),
    z.literal("ACTIVE"),
    z.literal("RESOLVE_IN_PROGRESS"),
    z.string(),
  ]),
  inProgressText: z.string().nullable(),
  inProgressAt: z.string().nullable(),
  lastChangedAt: z.string(),
  createdAt: z.string(),
});

export const VehicleAlertsSchema = z.array(VehicleAlertSchema);

export type VehicleAlert = z.infer<typeof VehicleAlertSchema>;

export type VehicleAlerts = z.infer<typeof VehicleAlertsSchema>;

export const VehicleAlertResponseSchema = z.object({
  alerts: VehicleAlertsSchema,
});

export type VehicleAlertResponse = z.infer<typeof VehicleAlertResponseSchema>;
