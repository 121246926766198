import { Checkbox, HStack, Stack, Text } from "@vygruppen/spor-react";
import { GlobalEventWarningText } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventWarningText";
import { FC } from "react";

type CheckboxProps = {
  editGlobalEvent: boolean;
  setEditGlobalEvent: (value: boolean) => void;
};
export const EditGlobalEventChekcbox: FC<CheckboxProps> = ({
  editGlobalEvent,
  setEditGlobalEvent,
}) => (
  <HStack alignItems="start">
    <Checkbox
      isChecked={editGlobalEvent}
      onChange={() => setEditGlobalEvent(!editGlobalEvent)}
    />
    <Stack>
      <Text variant="xs">Oppdater også global melding</Text>
      <GlobalEventWarningText />
    </Stack>
  </HStack>
);
