import { ChoiceChip, Flex, Skeleton, VStack } from "@vygruppen/spor-react";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { FC } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";
import { useBatchUseTrainRoute } from "../useTrainInfoContext";
import { toNonExcludedStops } from "./utils/listofExcludedStations";

const BASE_CASE = "INVALID";

export const TrainStoppingExtraAtStation: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = ({ trainId, formContext, trainFormPrefix }) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const { errors } = formContext.formState;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;

  const extraStopsFieldPath = `${trainFormPrefix}.extraStops` as const;

  const batchUseTrainRoute = useBatchUseTrainRoute();
  const { data: trainRoute, status: fetchStatus } = batchUseTrainRoute(trainId);

  const passThroughStops = toNonExcludedStops(
    trainRoute?.plannedRouteStops.filter((it) => it.justPassingThrough) ?? [],
  );

  const extraStops = getValues(extraStopsFieldPath) ?? [];

  if (fetchStatus === "pending") {
    return <Skeleton height={6} />;
  }

  if (fetchStatus === "error" || !trainRoute || trainRoute.stops.length <= 0) {
    return (
      <FailureMessage customMessage="Klarte ikke hente ut stoppene til toget. Prøv igjen, eller ta kontakt med IT dersom feilen vedvarer." />
    );
  }

  return (
    <VStack width="100%" alignItems="flex-start" gap={3}>
      <Flex width="100%" gap={1} wrap="wrap">
        {passThroughStops
          .filter((s) => extraStops.includes(s.stopId))
          .map((stop) => (
            <ChoiceChip
              key={stop.stopId}
              chipType="filter"
              size="sm"
              isChecked
              onChange={() => {
                const newExtraStops = extraStops.filter(
                  (s) => s !== stop.stopId,
                );
                setValue(extraStopsFieldPath, newExtraStops, {
                  shouldValidate: true,
                });
              }}
            >
              {stop.name}
            </ChoiceChip>
          ))}
      </Flex>
      <Flex width="50%">
        <VStack width="100%" alignItems="flex-start" gap={2}>
          <Select
            onChange={(e) => {
              const { value } = e.target;
              if (value !== BASE_CASE) {
                const newExtraStops = [...extraStops, value.toString()];
                setValue(extraStopsFieldPath, newExtraStops, {
                  shouldValidate: true,
                });
              }
            }}
            label="Velg stasjon"
          >
            <option value={BASE_CASE}>Ekstra stasjon</option>
            {passThroughStops
              .filter((stop) => !extraStops.includes(stop.stopId))
              .map((stop) => (
                <option value={stop.stopId} key={stop.stopId}>
                  {stop.name}
                </option>
              ))}
          </Select>
          <RenderErrorInPath errors={errors} errorPath={extraStopsFieldPath} />
        </VStack>
      </Flex>
    </VStack>
  );
};
