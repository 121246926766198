// Colors which come from Spor will typically be single names (colors.night) while
// custom colors _either_ have "...Custom" or "...<number>" to easily identify them

export const appPalette = {
  // Grey
  dimmedDarkGrey: "#414245",
  iron: "#4F5054",
  // Green
  green2000: "#010B0A",
  green1000: "#001A18",
  nightLighter: "#012925",
  blueGreen40: "#35625C",
  nightTeal70: "#4D6764",
  seaMist40: "#526D6A",
  nightTeal40: "#98A8A5",
  seaMist75: "#99B6B1",
  nightTeal20: "#CBD4D1",
  green25darker: "#F0F5F2",
  green25: "#F7FFFB",
  green10: "#FDFFFD",
  // Blue
  blue1000: "#091826",
  blue900: "#0C2033",
  blueberry: "#4B78B0",
  // Yellow
  yellow1000: "#262008",
  yellow900: "#332A0B",
  yellow700: "#84620A",
  // Orange
  orange1000: "#2B160A",
  orange900: "#3A1E0E",
  orange700: "#84441F",
  russet: "#84441F",
  // Red
  red900: "#371818",
  red800: "#581F1F",
  red700: "#9C2525",
  // Expansions
  roseCustom: "#FF3370",
  wineCustom: "#99002E",
  raspberryCustom: "#CC003D",

  // Old palette colors, should be removed
  wine: "#99002E",
  raspberry: "#CC003D",
  rose: "#FF3370",
  operations: "#383E42",
  blossom: "#FFCCDB",
  whiteSmoke: "#F7F7F7",
  whiteSnow: "#FDFDFD",
  darkGloomyShade: "#212121",
  iconHighlight: "#00685E",
  watermelon: "#FF99B8",
  yellow800: "#503D0A",
  darkerGrey: "#1C1C1D", // Based on grey 900
};
