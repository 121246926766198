import { generateStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/generateStretchName";
import {
  Leg,
  Stop,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";

export const getClickedStopPairs = (clickedStops: Stop[]) => {
  const singleClickedStop =
    clickedStops.length % 2 !== 0
      ? clickedStops[clickedStops.length - 1]
      : null;
  const stopPairs = singleClickedStop
    ? clickedStops.slice(0, -1)
    : clickedStops;
  return { singleClickedStop, stopPairs };
};

const getTextFromClickedStops = (stopPairs: Stop[]): string[] =>
  stopPairs
    .map((stop, i, array) =>
      i % 2 === 0 ? `${stop.name}\u2013${array[i + 1].name}` : "",
    )
    .filter((stop) => stop !== "");

export const getStopsTexts = (
  stops: Stop[],
  allStopsSelected: boolean,
): string[] =>
  allStopsSelected || stops.length > 400
    ? ["Hele landet"]
    : stops.map((stop) => stop.name);

export const getStretchName = (
  selectedLegs: Leg[],
  clickedStops: Stop[],
  allStopsSelected: boolean,
) => {
  const { stopPairs } = getClickedStopPairs(clickedStops);
  if (allStopsSelected) return ["Hele landet"];
  const stretchNamesFromLegs = generateStretchName(selectedLegs);
  return stretchNamesFromLegs.length > 0 || selectedLegs.length === 0
    ? stretchNamesFromLegs
    : getTextFromClickedStops(stopPairs);
};
