import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";

export type TrainResumedResponse = {
  trainsResumed: TrainResumed[];
  action: "ADD" | "REMOVE";
};

export type TrainResumed = {
  trainIdentifier: TrainIdentifier;
  originStopName: string;
  destinationStopName: string;
  actualTime: string;
  scheduledTime: string;
  trainInformationUuid: string | null;
  trainInformationSummary: string;
  previewText: string;
  eventType:
    | TrainEventTypeEnum.TRAIN_STOPPED
    | TrainEventTypeEnum.TRAIN_DELAY_EXPECTED;
};

export function handleTrainResumedResponse(
  data: TrainResumedResponse,
  trainsResumed: TrainResumed[],
  setTrainsResumed: (value: TrainResumed[]) => void,
) {
  if (data.action === "ADD") {
    setTrainsResumed([...trainsResumed, ...data.trainsResumed]);
  } else if (data.action === "REMOVE") {
    setTrainsResumed(
      trainsResumed.filter(
        (it) =>
          it.trainInformationUuid !==
          data.trainsResumed[0].trainInformationUuid,
      ),
    );
  }
}
