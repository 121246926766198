import { CloseOutline18Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getRoleText } from "shared/types/roles";
import { dropsRole } from "stores/dropsRole";
import { useSidePanelsState } from "stores/useSidePanelsState";
import styled, { useTheme } from "styled-components";
import * as ROUTES from "../../../../shared/utils/routes";
import { getTabsForRole } from "../../RoleContent/getTabsForRole";
import ExpandButton from "./ExpandButton";
import {
  ActiveTab,
  CloseTabs,
  InactiveTab,
  TabList,
  TabsContentWrapper,
  TabText,
} from "./TabsStyle";
import {
  BusTab,
  Tab,
  TrainTab,
  useActiveVehicleTabs,
  useSetActiveVehicleTabs,
} from "./useTabsContext";
import { TrainTabElement } from "./TrainTabElement";
import { BusTabElement } from "./BusTabElement";
import { useAlternativeTransportData } from "../../VehicleDetails/AlternativeTransportDetails/utils";

const MAX_TABS = 5;
const LAST_TAB = 6;

export const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Tabs = () => {
  const navigate = useNavigate();
  const { trainNumber, trainDate, tabIdParam, countryCode, vehicleUuid } =
    useParams();

  const hasParams = trainDate && trainNumber && countryCode;
  const hasBusParams = vehicleUuid;

  const { status, data } = useAlternativeTransportData(vehicleUuid);

  const tabName = data?.alternativeTransportResponseVehicle?.trainIds;

  const { role } = dropsRole();

  const activeVehicleTabs = useActiveVehicleTabs();
  const setActiveVehicleTabs = useSetActiveVehicleTabs();
  const storage = sessionStorage.getItem("openTabs");

  const {
    trainListOpen,
    setTrainListOpen,
    oploggTodoListOpen,
    setOploggTodoListOpen,
  } = useSidePanelsState();

  function arrSelectedVehicle(prevArr: Tab[], newTab: Tab) {
    return [...prevArr, newTab];
  }

  useEffect(() => {
    if (storage) {
      setActiveVehicleTabs(JSON.parse(storage));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("openTabs", JSON.stringify(activeVehicleTabs));
  }, [activeVehicleTabs]);

  useEffect(() => {
    const dontExist =
      activeVehicleTabs.filter(
        (it: Tab): it is TrainTab =>
          "trainNumber" in it &&
          it.trainNumber === trainNumber &&
          it.trainDate === trainDate &&
          it.countryCode === countryCode,
      ).length === 0;
    const notInStorage = storage
      ? JSON.parse(storage).filter(
          (it: Tab): it is TrainTab =>
            "trainNumber" in it &&
            it.trainNumber === trainNumber &&
            it.trainDate === trainDate &&
            it.countryCode === countryCode,
        ).length === 0
      : true;

    if (hasParams) {
      if (dontExist && notInStorage && activeVehicleTabs.length <= MAX_TABS) {
        setActiveVehicleTabs((prevArr: Tab[]) =>
          arrSelectedVehicle(prevArr, {
            trainNumber,
            trainDate,
            countryCode,
            tabId: tabIdParam!,
          } as TrainTab),
        );
      }

      if (dontExist && notInStorage && activeVehicleTabs.length === LAST_TAB) {
        setActiveVehicleTabs((prevArr: Tab[]) => {
          prevArr.shift();
          return arrSelectedVehicle(prevArr, {
            trainNumber,
            trainDate,
            countryCode,
            tabId: tabIdParam!,
          } as TrainTab);
        });
      }
    }
  }, [trainNumber, trainDate, countryCode]);

  useEffect(() => {
    if (status === "success" && tabName !== undefined) {
      const busIdFromUUID = `B-${tabName}`;

      const dontExist =
        activeVehicleTabs.filter(
          (it: Tab): it is BusTab => "busId" in it && it.busId === vehicleUuid,
        ).length === 0;
      const notInStorage = storage
        ? JSON.parse(storage).filter(
            (it: Tab): it is BusTab =>
              "busId" in it && it.busId === vehicleUuid,
          ).length === 0
        : true;

      if (hasBusParams) {
        if (dontExist && notInStorage && activeVehicleTabs.length <= MAX_TABS) {
          setActiveVehicleTabs((prevArr: Tab[]) =>
            arrSelectedVehicle(prevArr, {
              busId: vehicleUuid!,
              tabId: busIdFromUUID,
            } as BusTab),
          );
        }

        if (
          dontExist &&
          notInStorage &&
          activeVehicleTabs.length === LAST_TAB
        ) {
          setActiveVehicleTabs((prevArr: Tab[]) => {
            prevArr.shift();
            return arrSelectedVehicle(prevArr, {
              busId: vehicleUuid!,
              tabId: busIdFromUUID,
            } as BusTab);
          });
        }
      }
    }
  }, [vehicleUuid, status]);

  const theme = useTheme();

  const tabsForAGivenRole = getTabsForRole(role);

  return (
    <TabsContentWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* ExpandButton for TrainList */}
        <div style={{ marginBottom: "3px" }}>
          <ExpandButton
            label={"Togliste"}
            isOpen={trainListOpen}
            setOpen={setTrainListOpen}
          />
        </div>
        <TabList style={{ flexGrow: 1 }}>
          {activeVehicleTabs.map((tab: Tab, index: number) =>
            "trainNumber" in tab ? (
              <TrainTabElement
                key={index}
                trainTab={tab as TrainTab}
                index={index}
                activeVehicleTabs={activeVehicleTabs}
                setActiveVehicleTabs={setActiveVehicleTabs}
              />
            ) : (
              <BusTabElement
                key={index}
                busTab={tab as BusTab}
                index={index}
                activeVehicleTabs={activeVehicleTabs}
                setActiveVehicleTabs={setActiveVehicleTabs}
              />
            ),
          )}
          {activeVehicleTabs.length > 1 && (
            <CloseTabs
              title="Lukk alle"
              onClick={() => {
                sessionStorage.removeItem("openTabs");
                setActiveVehicleTabs([]);
                navigate(ROUTES.DASHBOARD);
              }}
            >
              <ButtonTextContainer>
                <Text variant="xs" margin="auto">
                  Lukk alle
                </Text>
                <CloseOutline18Icon
                  marginLeft="4px"
                  color={theme.colorTextSecondary}
                />
              </ButtonTextContainer>
            </CloseTabs>
          )}
        </TabList>
        <TabList>
          {tabsForAGivenRole?.map((it, i) => {
            const isActive = !hasParams && tabIdParam === it.id;
            const TabForRoleItem = isActive ? ActiveTab : InactiveTab;
            const icon = isActive ? it.iconActive : it.iconInactive;
            return (
              <TabForRoleItem
                key={i}
                title="Vis hovedside for valgt funksjon"
                onClick={() => {
                  navigate(
                    generatePath(ROUTES.LANDING, {
                      roleIdParam: role,
                      tabIdParam: it.id,
                    }),
                  );
                }}
              >
                <TabText>
                  {icon}
                  {getRoleText(it.name) ? getRoleText(it.name) : it.name}
                </TabText>
              </TabForRoleItem>
            );
          })}
        </TabList>
        <div style={{ marginBottom: "3px" }}>
          <ExpandButton
            label={"Oplogg"}
            isOpen={oploggTodoListOpen}
            setOpen={setOploggTodoListOpen}
            inverted
          />
        </div>
      </div>
    </TabsContentWrapper>
  );
};

export default Tabs;
