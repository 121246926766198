import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { GlobalEventRequest } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { GLOBAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";

export const usePostGlobalInfo = (uuid?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: GlobalEventRequest) =>
      mutationFnPOST<undefined, GlobalEventRequest>(
        `${getBackendUrl()}/global-information${uuid ? `/${uuid}/change` : "/create"}`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GLOBAL_EVENTS_QUERY_KEY] });
    },
  });
};
