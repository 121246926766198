import { toCalendarDate, toTime } from "@internationalized/date";
import {
  DatePicker,
  FormControl,
  HStack,
  Skeleton,
  Stack,
  TimePicker,
} from "@vygruppen/spor-react";
import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { InfrastructureFormFieldWithCustomInput } from "features/CenterContent/shared/operationalInformation/components/InfrastructureFormFieldWithCustomInput";
import { useOperationalInformationTypes } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

export const Prognosis: FC = () => {
  const { control, setValue, getValues } = useFormContext<FormSchema>();
  const prognosisType = useWatch({
    control,
    name: "infrastructureForm.prognosisType.type",
  });
  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const showDateTimeFields =
    prognosisType === "ESTIMATED_OPENING" ||
    prognosisType === "ESTIMATED_INFO_UPDATE";

  const prognosisTime = getValues("infrastructureForm.prognosisTime");

  switch (opInfoTypesStatus) {
    case "success":
      return (
        <Stack>
          <InfrastructureFormFieldWithCustomInput
            items={opInfoTypes?.prognoses}
            formFieldType="prognosisType"
          />
          {showDateTimeFields && (
            <HStack>
              <TimePicker
                label="Kl"
                value={toTime(prognosisTime)}
                minuteInterval={5}
                onChange={(time) => {
                  if (time !== null) {
                    setValue(
                      "infrastructureForm.prognosisTime",
                      prognosisTime.set({ ...time }),
                      { shouldValidate: true, shouldDirty: true },
                    );
                  }
                }}
              />
              <FormControl>
                <DatePicker
                  variant="base"
                  label="Dato"
                  value={toCalendarDate(prognosisTime)}
                  onChange={(date) => {
                    if (date !== null) {
                      setValue(
                        "infrastructureForm.prognosisTime",
                        prognosisTime.set({ ...date }),
                        { shouldValidate: true, shouldDirty: true },
                      );
                    }
                  }}
                />
              </FormControl>
            </HStack>
          )}
        </Stack>
      );
    case "pending":
      return <Skeleton height={6} />;
    case "error":
    default:
      return (
        <DropsStaticAlert variant="error">
          Noe gikk galt ved henting av prognoser. Last siden på nytt for å prøve
          igjen. Ta kontakt med IT hvis feilen vedvarer.
        </DropsStaticAlert>
      );
  }
};
