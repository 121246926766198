import { cssVar, StyleFunctionProps } from "@chakra-ui/react";
import { theme as defaultTheme, extendTheme } from "@vygruppen/spor-react";
import colors from "shared/theme/colors";
import { darkTheme } from "./darkTheme";
import { lightTheme } from "./lightTheme";

function mode<T>(
  light: T,
  dark: T,
): (props: Record<string, any> | StyleFunctionProps) => T {
  return (props: Record<string, any> | StyleFunctionProps) =>
    props.colorMode === "light" ? light : dark;
}

const getBackgroundColor = (colorMode: "light" | "dark") =>
  colorMode === "light" ? "white" : "darkGrey";
const $arrowBg = cssVar("popper-arrow-bg");

const themeColors = (props: Record<string, any> | StyleFunctionProps) =>
  mode(lightTheme, darkTheme)(props);

// override Spor default theme
export const theme = extendTheme(
  {
    styles: {
      global: (props: StyleFunctionProps) => ({
        "html, body": {
          fontFamily: '"Vy Sans", sans-serif',
          backgroundColor: mode("lightGrey", colors.operations)(props),
        },
      }),
    },
    components: {
      Accordion: {
        baseStyle: () => ({
          container: {
            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
          },
        }),
      },
      Tabs: {
        baseStyle: (props: StyleFunctionProps) => ({
          tablist: {
            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
          },
          tab: {
            _selected: {
              pointerEvents: "auto",
              _hover: {
                textColor: `${mode("white", "darkTeal")(props)} !important`,
                backgroundColor: `${mode("darkTeal", "blueGreen")(props)} !important`,
                outline: "none !important",
              },
              _active: {
                backgroundColor: `${mode(
                  colors.mint,
                  colors.whiteAlpha["100"],
                )(props)} !important`,
                textColor: `${mode("darkTeal", "white")(props)} !important`,
              },
            },
          },
        }),
      },
      Select: {
        baseStyle: (props: StyleFunctionProps) => ({
          field: {
            background: `${themeColors(props).colorBackgroundSecondary}`,
            border: `1px solid ${themeColors(props).colorOutline}`,
          },
        }),
      },
      Textarea: {
        baseStyle: (props: StyleFunctionProps) => ({
          background: themeColors(props).colorBackgroundSecondary,
          border: `1px solid ${themeColors(props).colorOutline}`,
        }),
      },
      Input: {
        baseStyle: (props: StyleFunctionProps) => ({
          field: {
            background: `${themeColors(props).colorBackgroundSecondary} !important`,

            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
            _active: {
              backgroundColor: `${mode(
                colors.mint,
                colors.whiteAlpha["100"],
              )(props)} !important`,
            },
          },
        }),
      },
      Button: {
        baseStyle: () => ({
          outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
        }),
      },
      CardSelect: {
        baseStyle: () => ({
          trigger: {
            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
          },
        }),
      },
      Modal: {
        baseStyle: (props: StyleFunctionProps) => ({
          closeButton: {
            color: mode("darkGrey", "lightGrey")(props),
            _hover: {
              backgroundColor: mode("seaMist", "pine")(props),
            },
            _active: {
              backgroundColor: mode("mint", "whiteAlpha.200")(props),
            },
          },
          dialog: {
            color: mode("darkGrey", "lightGrey")(props),
            margin: "auto auto",
            backgroundColor: `${themeColors(props).colorBackgroundSecondary}`,
          },
        }),
      },
      Divider: {
        baseStyle: (props: StyleFunctionProps) => ({
          borderColor: mode("blackAlpha.100", "whiteAlpha.100")(props),
        }),
        defaultProps: {
          size: "sm",
        },
      },
      Toast: {
        baseStyle: {
          borderWidth: "1px",
        },
        variants: {
          success: (props: StyleFunctionProps) => ({
            backgroundColor: themeColors(props).colorSuccessFill,
            borderColor: themeColors(props).colorSuccessOutline,
            color: themeColors(props).colorSuccessTextMain,
            svg: { color: themeColors(props).colorSuccess },
          }),
          info: (props: StyleFunctionProps) => ({
            backgroundColor: themeColors(props).colorInfoFill,
            borderColor: themeColors(props).colorInfoOutline,
            color: themeColors(props).colorInfoTextMain,
            svg: { color: themeColors(props).colorInfo },
          }),
          error: (props: StyleFunctionProps) => ({
            backgroundColor: themeColors(props).colorAlarmFill,
            borderColor: themeColors(props).colorAlarmOutline,
            color: themeColors(props).colorAlarmTextMain,
            svg: { color: themeColors(props).colorAlarm },
          }),
        },
      },
      Tooltip: {
        baseStyle: (props: StyleFunctionProps) => ({
          bg: mode("teal.500", "green.100")(props),
          [$arrowBg.variable]: mode("colors.darkTeal", "colors.seaMist")(props),
          color: getBackgroundColor(props.colorMode),
          fontSize: "xs",
        }),
      },
      Table: {
        variants: {
          outline: (props: StyleFunctionProps) => ({
            th: {
              backgroundColor: getBackgroundColor(props.colorMode),
            },
            td: {
              borderColor: mode("blackAlpha.100", "whiteAlpha.200")(props),
            },
          }),
        },
      },
      Datepicker: {
        variants: {
          base: (props: StyleFunctionProps) => ({
            dateCell: {
              backgroundColor: getBackgroundColor(props.colorMode),
            },
            wrapper: {
              backgroundColor: "transparent",
              position: "relative",
            },
            calendarTriggerButton: {
              backgroundColor: "transparent",
            },
          }),
        },
        baseStyle: (props: StyleFunctionProps) => ({
          calendarTriggerButton: {
            borderRadius: "0.75rem",
            outlineWidth: "1px",
            outlineStyle: "solid",
            outlineColor: "inherit",
          },
          calendarPopover: {
            backgroundColor: getBackgroundColor(props.colorMode),
          },
          arrow: {
            [$arrowBg.variable]: mode("white", "colors.darkGrey")(props),
          },
          wrapper: {
            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border
            _active: {
              backgroundColor: mode(
                colors.mint,
                colors.whiteAlpha["100"],
              )(props),
            },
          },
        }),
      },
      Card: {
        baseStyle: (props: StyleFunctionProps) => ({
          backgroundColor: getBackgroundColor(props.colorMode),
        }),
      },
      ListBox: {
        baseStyle: (props: StyleFunctionProps) => ({
          container: {
            border: "1px solid",
            borderColor: mode("silver", "dimGrey")(props),
            shadow: "none",
          },
        }),
      },
      Checkbox: {
        baseStyle: (props: StyleFunctionProps) => ({
          container: {
            _hover: {
              "input:enabled:not([aria-invalid]) + .chakra-checkbox__control": {
                borderColor: mode("darkTeal", "white")(props),
                _active: {
                  backgroundColor: `#00453E !important`,
                },
              },
            },
            _focus: {
              backgroundColor: `#00453E !important`,
            },
          },
          control: {
            backgroundColor: getBackgroundColor(props.colorMode),
            borderColor: mode(colors.osloGrey, "gray")(props),
          },
        }),
      },
      Badge: {
        sizes: {
          xs: {
            fontSize: "2xs",
            minHeight: 4,
          },
        },
      },
      ChoiceChip: {
        baseStyle: (props: StyleFunctionProps) => ({
          container: {
            outlineOffset: "-2px !important", // Because Spor is using outline instead of boxshadow/border

            // Add styling for the html built-in disabled state
            _disabled: {
              cursor: "default",
              _active: {
                // Overwrite the "active" state that occurs while clicking, in disabled state
                backgroundColor: `${mode("white", "transparent")(props)}`,
                boxShadow: `inset 0 0 0 1px ${colors.celadon}`,
                color: mode("darkTeal", "white")(props),
                _checked: {
                  backgroundColor: colors.pine,
                  boxShadow: `inset 0 0 0 1px ${colors.celadon}`,
                  color: colors.white,
                },
              },
              _hover: {
                // Overwrite the hover state, in disabled state
                backgroundColor: `${mode("white", "transparent")(props)}`,
                boxShadow: `inset 0 0 0 1px ${colors.celadon}`,
                color: mode("darkTeal", "white")(props),
                _checked: {
                  boxShadow: `inset 0 0 0 1px ${colors.celadon}`,
                  color: colors.white,
                },
              },
            },
            // Overwrite the hover state
            _hover: {
              _checked: {
                backgroundColor: `${mode("darkTeal", "00453E")(props)}`,
                borderRadius: "9px",
                color: mode("white", "darkTeal")(props),
              },
              backgroundColor: "transparent",
              color: `${mode("dark", "white")(props)}`,
            },
            // Overwrite the active state
            _active: {
              backgroundColor: `${mode(
                colors.mint,
                colors.whiteAlpha["100"],
              )(props)}`,
            },
          },
        }),
      },
    },
  },
  defaultTheme,
);
