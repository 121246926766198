import { BoxProps, ExpandableAlert } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";

export type Variant =
  | "info"
  | "success"
  | "warning"
  | "alt-transport"
  | "error";

const getColorSchemeForVariant = (variant: Variant, theme: DefaultTheme) => {
  switch (variant) {
    case "info":
      return {
        backgroundColor: theme.colorInfoFill,
        borderColor: theme.colorInfoOutline,
        color: theme.colorInfoTextMain,
      };
    case "success":
      return {
        backgroundColor: theme.colorSuccessFill,
        borderColor: theme.colorSuccessOutline,
        color: theme.colorSuccessTextMain,
      };
    case "warning":
      return {
        backgroundColor: theme.colorWarningFill,
        borderColor: theme.colorWarningOutline,
        color: theme.colorWarningTextMain,
        $titleColor: theme.colorWarningTextMain,
      };

    case "alt-transport":
      return {
        backgroundColor: theme.colorInfoFill,
        borderColor: theme.colorInfoOutline,
        color: theme.colorInfoTextMain,
      };

    case "error":
      return {
        backgroundColor: theme.colorAlarmFill,
        borderColor: theme.colorAlarmOutline,
        color: theme.colorAlarmTextMain,
      };
    default:
      return {
        backgroundColor: theme.colorNeutralFill,
        borderColor: theme.colorNeutralOutline,
        color: theme.colorNeutralTextMain,
      };
  }
};

const ExpandableAlertStyled = styled(ExpandableAlert)<{
  $titleColor: string;
}>`
  svg,
  span {
    color: ${({ $titleColor }) => $titleColor};
  }

  & :active button {
    background-color: ${({ theme }) => theme.colorAlarmFillPulse};
  }
`;

export const DropsExpendableAlert = ({
  variant,
  children,
  ...rest
}: {
  variant: Variant;
  children: ReactNode;
} & BoxProps) => {
  const theme = useTheme();

  return (
    <ExpandableAlertStyled
      variant="warning"
      background
      {...rest}
      {...getColorSchemeForVariant(variant, theme)}
    >
      {children}
    </ExpandableAlertStyled>
  );
};
