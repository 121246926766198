import { log, LogLevel } from "api/cloudWatch";
import {
  distributionInput,
  dropsLogTextInput,
  optionalInputWithDescription,
  reasonInput,
} from "features/CenterContent/shared/operationalInformation/utils";
import { safeCapitalise } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { countryCodeSchema } from "features/VehicleList/TrainList/types";
import { FieldError } from "react-hook-form";
import { z } from "zod";

export type RouteSection = z.infer<typeof routeSectionSchema>;

export const missingStationErrorMessage =
  "Du må velge en stasjon for å opprette hendelsen";
export const missingStationsErrorMessage =
  "Du må velge stasjoner for å opprette hendelsen";
export const missingStationsOptionalPluralMessage =
  "Du må velge stasjon(er) for å opprette hendelsen";

const routeSectionSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("AT_STOP"),
    fromStop: z.string().min(1, { message: missingStationErrorMessage }),
  }),
  z.object({
    type: z.literal("BETWEEN_STOPS"),
    fromStop: z.string().min(1, { message: missingStationErrorMessage }),
    toStop: z.string().min(1, { message: missingStationErrorMessage }),
  }),
  z.object({
    type: z.literal("WHOLE_ROUTE"),
    fromStop: z.string().min(1, { message: missingStationErrorMessage }),
    toStop: z.string().min(1, { message: missingStationErrorMessage }),
  }),
]);

export type RouteSectionSchema = z.infer<typeof routeSectionSchema>;

const dayInMinutesInput = z.preprocess(
  (val) => (val ? Number(val) : undefined),
  z
    .number({ message: "Forsinkelse må være et tall" })
    .int()
    .min(1, { message: "Forsinkelsen må være minimum 1 minutt" })
    .max(24 * 60)
    .optional(),
);

const trainRouteSection = {
  trainRouteSection: routeSectionSchema,
  affectedStops: z
    .string()
    .array()
    .min(1, { message: missingStationsErrorMessage }),
};

const trainStoppedSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_STOPPED),
  trainRouteSection: routeSectionSchema.optional(),
  dropsLogText: dropsLogTextInput,
});

const trainCancelledSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CANCELLED),
  ...trainRouteSection,
  dropsLogText: dropsLogTextInput,
});

const capacityUnit = z.enum(["CARS", "MULTIPLE_UNIT"]);

const trainCapacityReducedSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED),
  ...trainRouteSection,
  oldCapacity: z.number().int().nonnegative(),
  newCapacity: z.number().int().nonnegative(),
  oldCapacityUnit: capacityUnit,
  newCapacityUnit: capacityUnit,
  dropsLogText: dropsLogTextInput,
});

const trainCapacityIncreasedSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED),
  ...trainRouteSection,
  oldCapacity: z.number().int().nonnegative(),
  newCapacity: z.number().int().nonnegative(),
  oldCapacityUnit: capacityUnit,
  newCapacityUnit: capacityUnit,
  dropsLogText: dropsLogTextInput,
});

const trainDelayedSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_DELAYED),
  ...trainRouteSection,
  delayInMinutes: dayInMinutesInput,
  dropsLogText: dropsLogTextInput,
});

const trainLateToTrackSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_LATE_TO_TRACK),
  ...trainRouteSection,
  minutesLate: dayInMinutesInput,
  dropsLogText: dropsLogTextInput,
});

const trainDelayExpectedSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_DELAY_EXPECTED),
  ...trainRouteSection,
  delayInMinutes: dayInMinutesInput,
  dropsLogText: dropsLogTextInput,
});

const trainClosedSetSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CLOSED_SET),
  dropsLogText: dropsLogTextInput,
  ...trainRouteSection,
});

const trainNotStoppingAtStationSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_NOT_STOPPING_AT_STATION),
  skippedStops: z
    .string()
    .array()
    .min(1, { message: missingStationsOptionalPluralMessage }),
  dropsLogText: dropsLogTextInput,
});

const trainMissingProductsEnum = z.enum([
  "FIRST_CLASS_CAR",
  "SLEEPING_CAR",
  "FAMILY_CAR",
  "ANIMALS_ALLOWED_CAR",
  "ANIMALS_NOT_ALLOWED_CAR",
  "RESTAURANT_CAR",
  "WIFI",
  "QUIET_SECTION",
  "FUNCTIONING_TOILETS",
  "COMPARTMENT",
  "REST",
  "PLUS_NIGHT",
]);

export type TrainMissingProductsEnum = z.infer<typeof trainMissingProductsEnum>;

const trainMissingProductsCoverageEnum = z.enum([
  "WHOLE_TRAIN",
  "FRONT_SET",
  "REAR_SET",
]);

export type TrainMissingProductsCoverageEnum = z.infer<
  typeof trainMissingProductsCoverageEnum
>;

const trainMissingProductSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_MISSING_PRODUCT),
  ...trainRouteSection,
  dropsLogText: dropsLogTextInput,
  products: trainMissingProductsEnum.array().min(1),
  coverage: trainMissingProductsCoverageEnum,
});

const trainStoppingExtraAtStationSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_STOPPING_EXTRA_AT_STATION),
  extraStops: z
    .string()
    .array()
    .min(1, { message: missingStationsOptionalPluralMessage })
    .default([]),
  dropsLogText: dropsLogTextInput,
});

export const severityErrorMessage =
  "Du må velge alvorlighetsgrad for å opprette hendelsen";
const trainCustomEventSeverityEnum = z.enum(["INFO", "WARNING"], {
  message: severityErrorMessage,
});

export type TrainCustomEventSeverityEnum = z.infer<
  typeof trainCustomEventSeverityEnum
>;

const trainCustomEventSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CUSTOM),
  affectedStops: z
    .string()
    .array()
    .min(1, { message: missingStationsErrorMessage }),
  severity: trainCustomEventSeverityEnum,
  distributions: distributionInput,
});

export type TrainCustomEventSchema = z.infer<typeof trainCustomEventSchema>;

export const missingRouteErrorMessage =
  "Du må velge en rute for å opprette hendelsen";

const trainChangedRouteSchema = z.object({
  type: z.literal(TrainEventTypeEnum.TRAIN_CHANGED_ROUTE),
  affectedStops: z
    .string()
    .array()
    .min(1, { message: missingStationsErrorMessage }),
  newRoute: z.string().array(),
  cancelledStops: z.string().array(),
  // min(2) as to not send when placeholder value "-" is selected
  originalRouteName: z.string().min(2, { message: missingRouteErrorMessage }),
  newRouteName: z.string().min(2, { message: missingRouteErrorMessage }),
  dropsLogText: dropsLogTextInput,
});

export const typeErrorMessage =
  "Du må velge type hendelse for å kunne opprette hendelsen";

export const trainFormSchema = z
  .discriminatedUnion(
    "type",
    [
      trainStoppedSchema,
      trainCancelledSchema,
      trainCapacityReducedSchema,
      trainCapacityIncreasedSchema,
      trainDelayedSchema,
      trainDelayExpectedSchema,
      trainNotStoppingAtStationSchema,
      trainMissingProductSchema,
      trainStoppingExtraAtStationSchema,
      trainClosedSetSchema,
      trainLateToTrackSchema,
      trainCustomEventSchema,
      trainChangedRouteSchema,
    ],
    {
      errorMap: () => ({
        message: typeErrorMessage,
      }),
    },
  )
  .and(
    z.object({
      action: optionalInputWithDescription,
      reason: reasonInput,
      incidentId: z.string().optional(),
    }),
  )
  .superRefine((val, ctx) => {
    /**
     * Some rules, for instance rules that depend on other fields, are not possible to express on the individual
     * fields, and must be expressed here as refinements.
     */
    if (val.type === TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED) {
      if (val.newCapacity <= val.oldCapacity) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: val.oldCapacity + 1,
          type: "number",
          inclusive: true,
          message: "Ny kapasitet må være høyere enn gammel kapasitet",
          path: [...ctx.path, "newCapacity"],
        });
      }
    }

    if (val.type === TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED) {
      if (val.newCapacity >= val.oldCapacity) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: val.oldCapacity - 1,
          type: "number",
          inclusive: true,
          message: "Ny kapasitet må være lavere enn gammel kapasitet",
          path: [...ctx.path, "newCapacity"],
        });
      }
    }

    if (val.type !== TrainEventTypeEnum.TRAIN_CUSTOM) {
      if (val.dropsLogText.enabled && val.dropsLogText.texts.NOB.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 2,
          type: "string",
          inclusive: true,
          message: "Må fylles ut med tekst til oplogg",
          path: ["dropsLogText", "texts", "NOB"],
        });
      }
    }

    return true;
  });

export type TrainFormSchema = z.infer<typeof trainFormSchema>;

export type TrainEventType = TrainFormSchema["type"];

/*
 * Schema for a single Operational Train Information form, used for validation.
 * The schema is a discriminated union of the different operational train information subtypes.
 * */
export const singleTrainFormSchema = z.object({
  trainForm: trainFormSchema,
});

export type SingleTrainFormSchema = z.infer<typeof singleTrainFormSchema>;

const toReadableFormField = (key: string) => {
  switch (key) {
    case "type":
      return "mangler hendelse";
    case "reason":
      return "mangler årsak";
    case "affectedStops":
    case "skippedStops":
    case "extraStops":
    case "trainRouteSection":
      return "mangler stopp";
    case "products":
      return "velg minst et manglende produkt";
    case "coverage":
      return "mangler dekning";
    case "originalRouteName":
      return null;
    case "newRouteName":
      return "mangler ny rute";
    case "severity":
      return "mangler alvorlighetsgrad";
    case "distributions":
      return "mangler egendefinert tekst";
    case "dropsLogText":
      return "mangler melding til Operativ logg";
    default:
      log(
        LogLevel.info,
        "[FormSchema]",
        `Missing description for [key]: ${key}`,
      );
      return `${key} mangler verdi`;
  }
};

const directionEnum = z.enum(["ODD", "EVEN"]);
export type DirectionEnum = z.infer<typeof directionEnum>;

const trainIdentifier = z.object({
  identifier: z.string(),
  nominalDate: z.string(),
  countryCode: countryCodeSchema,
});

export const trainFormGroup = z.object({
  trainSeries: z.string(),
  groupLabel: z.string().optional(),
  route: z.string(),
  direction: directionEnum,
  trainIdsInGroup: z.array(trainIdentifier),
  trainForm: trainFormSchema,
});

export type TrainFormGroup = z.infer<typeof trainFormGroup>;

/**
 * Schema for the batch modal, to validate multiple groups of trains at the same time.
 * The catchall is a fallback function to be used in validation of any keys not defined
 * in the object. In this case that includes all possible keys, which allows us to use
 * the key as an identifier for the group without pre-defining it
 */
export const groupedTrainFormSchema = z
  .object({})
  .catchall(z.array(trainFormGroup));

export type GroupedTrainFormSchema = z.infer<typeof groupedTrainFormSchema>;

/**
 * React-hook-form isn't great at handling dynamic keys and nested
 * strings entirely break down if you use them, so we have to pretend
 * that we don't use them. I'm sorry.
 *
 * The keys are actually a bunch of dynamic strings, generated by us
 * in the grouping process to identify each group of trains with similar
 * characteristics.
 */
const trickFormHookSchema = z.object({
  // eslint-disable-next-line no-template-curly-in-string
  "${groupKey}": z.array(trainFormGroup),
});

export type TrickFormHookGroupedTrainFormSchema = z.infer<
  typeof trickFormHookSchema
>;

/**
 * Union type to help casting in nested fields
 * This is a workaround for how react-hook-form handles field
 * paths
 */
export type EitherTrainFormSchema =
  | GroupedTrainFormSchema
  | SingleTrainFormSchema;

// SafeParse a trainForm and gives user legible message
export const trainFormSchemaValidatorMessage = (
  formState: z.infer<typeof trainFormSchema>,
): string | undefined => {
  const formValidator = trainFormSchema.safeParse(formState);

  // undefined if valid
  if (formValidator.success) {
    return undefined;
  }

  const errors = formValidator.error.flatten().fieldErrors;

  const legibleErrors = Object.keys(errors ?? {})
    .map((key) => toReadableFormField(key))
    .filter((v) => v !== null)
    .join(", ");

  // Should not occur, but better safe than sorry
  if (legibleErrors.length === 0) {
    return "Kan ikke opprette hendelse";
  }

  return safeCapitalise(legibleErrors);
};

/**
 * This type can be used to hard cast a `type` key if we do not care for the LiteralUnion type.
 *
 *
 * FieldError contains a key `type` which is defined as LiteralUnion<keyof RegisterOptions, string>.
 *    Seems like `type` is a reserved word in react-hook-form: https://github.com/react-hook-form/react-hook-form/issues/9500#issuecomment-1335861895
 *
 * Since `trainFormSchema` contains a `type` key, these two types are merged when exposing error type.
 *    The merged type then contains `FieldError | LiteralUnion<...>` which is "wrong".
 */
export type FieldErrorOrUndefined = FieldError | undefined;
