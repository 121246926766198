import { Button, Flex } from "@vygruppen/spor-react";
import { FCWC } from "shared/utils/FCWC";
import styled from "styled-components";

const ButtonWithTransition = styled(Button)`
  * {
    transition: all 0.3s ease;
  }
`;

type ButtonProps = {
  variant: "tertiary" | "secondary";
  active: boolean;
  setActive: (value: boolean) => void;
};

export const IconButtonWithTransition: FCWC<ButtonProps> = ({
  variant,
  active,
  setActive,
  children,
}) => (
  <ButtonWithTransition
    variant={variant}
    w="42px"
    h="42px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    onClick={() => setActive(!active)}
  >
    <Flex>{children}</Flex>
  </ButtonWithTransition>
);
