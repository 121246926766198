import { Thead, Tr } from "@chakra-ui/react";
import { Table, Tbody, Td, Th } from "@vygruppen/spor-react";
import { VehicleAlert } from "features/CenterContent/shared/TilsynAlerts/schema";
import { sortFunction } from "features/CenterContent/shared/TilsynAlerts/utils";
import React from "react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { TableSkeleton } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import { Text } from "shared/components/typography/TitleStyles";
import styled, { useTheme } from "styled-components";
import TilsynAlertRow from "./TilsynAlertRow";

const minimizedColumns = ["Individ", "Aktivert", "Type", "Tiltak"];
const tableColumns = ["Togsett", ...minimizedColumns, "Tilstand", " "]; // empty column title for the action column

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

interface TilsynAlertProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  alerts: VehicleAlert[] | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  fullSize?: boolean;
}

const TilsynAlerts = ({
  alerts,
  isLoading,
  isSuccess,
  fullSize = false,
  ...props
}: TilsynAlertProps) => {
  const theme = useTheme();

  const columns = fullSize ? tableColumns : minimizedColumns;

  const dataToShow = alerts && alerts.length > 0;

  return (
    <Container {...props}>
      <Text as="h2" bold style={{ marginBottom: "12px", marginTop: "12px" }}>
        Alarmer i Tilsyn
      </Text>
      <Table
        variant="simple"
        colorScheme="grey"
        size="sm"
        display="flex"
        flexDirection="column"
        overflow="scroll"
      >
        <Thead position="sticky" top="0" zIndex="1">
          <Tr>
            {columns.map((column, index) => (
              <Th
                style={{
                  borderRadius: `${index === 0 ? "12px" : "0px"} ${
                    index === tableColumns.length - 1 ? "12px" : "0px"
                  } 0px 0px`,
                  backgroundColor: theme.colorBackgroundTertiary,
                  border: "0px",
                }}
                key={column}
              >
                {column}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <TableSkeleton numOfRows={10} numOfColumns={columns.length} />
          )}

          {isSuccess && dataToShow
            ? alerts
                .toSorted(sortFunction)
                .map((alert) => (
                  <TilsynAlertRow
                    key={alert.alertId}
                    alert={alert}
                    fullSize={fullSize}
                  />
                ))
            : isSuccess &&
              !dataToShow && (
                <Tr>
                  <Td colSpan={tableColumns.length} p={5}>
                    <NoDataMessage reason="Det er ingen aktive alarmer i Tilsyn for øyeblikket" />
                  </Td>
                </Tr>
              )}
        </Tbody>
      </Table>
    </Container>
  );
};

export default TilsynAlerts;
