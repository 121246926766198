import React from "react";
import styled from "styled-components";
import { Text } from "../../typography/TitleStyles";

interface NoDataProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  reason: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const NoDataMessage = ({ reason, ...props }: NoDataProps) => (
  <Wrapper {...props}>
    <Text bold>Ingen treff</Text>
    <Text style={{ maxWidth: "300px" }}>{reason}</Text>
  </Wrapper>
);
