import { ModalBody, ModalCloseButton, ModalHeader } from "@chakra-ui/react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
} from "@vygruppen/spor-react";
import { useStationEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useStationEvents";
import { StationEventItem } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventItem";
import { FC, useState } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EventActionProps = {
  uuids: string[];
};

export const StationEventAction: FC<EventActionProps> = ({ uuids }) => {
  const queries = useStationEvents(uuids);
  const isQueriesPending = queries.some((query) => query.isPending);
  const isAllQueriesError = queries.every((query) => query.isError);
  const closeAndResetStretchBuilder = useStretchBuilder(
    (state) => state.closeAndResetStretchBuilder,
  );
  const [askConfirmationForUuid, setAskConfirmationForUuid] = useState<
    string | null
  >(null);

  return (
    <Modal isOpen onClose={closeAndResetStretchBuilder} size="lg">
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader mb={2}>Aktive stasjonshendelser</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2} w="100%">
          {isQueriesPending && <Skeleton height={6} />}
          {isAllQueriesError && (
            <DropsStaticAlert variant="error">
              Kunne ikke hente hendelser for stasjon.
            </DropsStaticAlert>
          )}
          {!isQueriesPending && !isAllQueriesError && (
            <ul>
              {queries.map((query, i) => {
                if (query.isSuccess) {
                  return (
                    <StationEventItem
                      key={query.data.uuid}
                      stationEvent={query.data}
                      isFirstItem={i === 0}
                      onClose={closeAndResetStretchBuilder}
                      askConfirmation={
                        askConfirmationForUuid === query.data.uuid
                      }
                      setAskConfirmation={setAskConfirmationForUuid}
                    />
                  );
                }
                return (
                  <DropsStaticAlert variant="error" key={i} my={1}>
                    Kunne ikke hente hendelse.
                  </DropsStaticAlert>
                );
              })}
            </ul>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
