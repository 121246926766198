import { Button, Flex } from "@vygruppen/spor-react";
import { SVGProps } from "react";
import { IconButtonWithTransition } from "shared/components/buttons/IconButtonWithTransition";
import styled, { DefaultTheme, css } from "styled-components";

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="5" y1="12" x2="19" y2="12" />
    <line x1="12" y1="5" x2="12" y2="19" />
  </svg>
);

// Same as AddFill24Icon, but works with transition (line instead of path).
// Kind of an overkill solution just for transition, but I misinterpreted
// the design and thought the icons did not exist in Spor already.
// Now that I've already made this killer transition animation, lets keep it.
const PlusFill24Icon = styled(PlusIcon)`
  line {
    stroke-width: 2;
    stroke: ${({ theme }) => theme.colorSuccessTextMain};
    stroke-linecap: round;
  }
`;

const ToCrossIcon = (theme: DefaultTheme) => css`
  line {
    stroke-width: 1;
    stroke: ${theme.colorTextMain};
  }
  transform: rotate(-45deg) scale(1.25);
`;

// Changes to CrossIcon once $active is true. Is animated.
const PlusOrCrossIcon = styled(PlusFill24Icon)<{ $active: boolean }>`
  ${({ $active, theme }) => $active && ToCrossIcon(theme)}
`;

export const PlusButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: any;
}) => (
  <IconButtonWithTransition
    variant={active ? "tertiary" : "secondary"}
    active={active}
    setActive={setActive}
  >
    <PlusOrCrossIcon height="24px" $active={active} />
  </IconButtonWithTransition>
);
