import { toCalendarDate, toTime } from "@internationalized/date";
import {
  Box,
  DatePicker,
  FormControl,
  FormErrorMessage,
  Text,
  TimePicker,
} from "@vygruppen/spor-react";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

type VisibilityInputProps = {
  isRelatedToIncident: boolean;
  formMethods: UseFormReturn<GlobalFormSchema>;
};

export const VisibilityInput: FC<VisibilityInputProps> = ({
  isRelatedToIncident,
  formMethods,
}) => {
  const { watch, setValue, formState } = formMethods;
  const visibility = watch("globalForm.visibility");
  const fieldError = formState.errors.globalForm?.visibility;
  const dateError =
    !!fieldError &&
    visibility.type === "TIMED" &&
    visibility.fromTime.day > visibility.toTime.day;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      rowGap={1}
      columnGap={2}
      width="100%"
      paddingY={2}
    >
      <Text variant="xs" gridColumn="span 2">
        Synlig inntil:
      </Text>
      {isRelatedToIncident && (
        <Text variant="xs" fontWeight="bold" gridColumn="span 2">
          Følger synlighet til tilhørende infrastrukturmelding
        </Text>
      )}
      {visibility.type === "TIMED" && (
        <>
          <FormControl isInvalid={dateError}>
            <DatePicker
              withPortal={false}
              width="100%"
              variant="base"
              label="Dato"
              value={toCalendarDate(visibility.toTime)}
              onChange={(date) => {
                if (date !== null) {
                  setValue("globalForm.visibility.toTime.day", date.day ?? 0);
                  setValue(
                    "globalForm.visibility.toTime.month",
                    date.month ?? 0,
                  );
                  setValue("globalForm.visibility.toTime.year", date.year ?? 0);
                }
              }}
            />
            <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!fieldError && !dateError}>
            <TimePicker
              width="100%"
              label="Tidspunkt"
              value={toTime(visibility.toTime)}
              minuteInterval={5}
              onChange={(time) => {
                if (time !== null) {
                  setValue("globalForm.visibility.toTime.hour", time.hour ?? 0);
                  setValue(
                    "globalForm.visibility.toTime.minute",
                    time.minute ?? 0,
                  );
                }
              }}
            />
            <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
          </FormControl>
        </>
      )}
    </Box>
  );
};
