import { useState } from "react";
import constate from "constate";

export type Tab = TrainTab | BusTab;

export type TrainTab = {
  countryCode: string;
  trainNumber: string;
  trainDate: string;
  tabId: string;
};

export type BusTab = {
  busId: string;
  tabId: string | undefined;
};

function useTabs() {
  const [activeTrainTabs, setActiveTrainTabs] = useState<Tab[]>([]);

  return {
    activeTrainTabs,
    setActiveTrainTabs,
  };
}

const [TabsProvider, useActiveVehicleTabs, useSetActiveVehicleTabs] = constate(
  useTabs,
  (value) => value.activeTrainTabs,
  (value) => value.setActiveTrainTabs,
);

export { TabsProvider, useActiveVehicleTabs, useSetActiveVehicleTabs };
