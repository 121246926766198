import { UserRole } from "shared/types/roles";

export type AdminLink = {
  label: string;
  url: string;
  roles: UserRole[];
};

export const adminLinks: AdminLink[] = [
  {
    label: "Norske tog",
    url: "https://www.norsketog.no/tog",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Fili",
    url: "http://fili.nsb.no",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Fm",
    url: "http://fm.nsb.no",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Mantena Lodalen",
    url: "http://driftsbilde.mantena.no/Driftsbilde/Driftsbilde/Lodalen/LODriftsStatus.html",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Mantena Sundland",
    url: "http://driftsbilde.mantena.no/Driftsbilde/Driftsbilde/Sundland/SLDriftsStatus.html",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Telma",
    url: "http://telma.nsb.no/fleetStatusGlobal.php",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "TIM",
    url: "https://tim.nsb.no",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "v.nsb.no",
    url: "https://v.nsb.no/login/auth;jsessionid=8852FA3DA01248593517F847ED6747A1",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Sjt",
    url: "https://www.sjt.no/jernbane/regelverk/lover-og-forskrifter/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Varsom",
    url: "https://varsom.no/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Kart",
    url: "https://kart.1881.no/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Tv",
    url: "https://tv.nsb.no/login/auth",
    roles: [
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
    ],
  },
  {
    label: "Zalaris",
    url: "https://portal.zalaris.com/irj/portal?",
    roles: [
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
    ],
  },
  {
    label: "Bussfortog",
    url: "https://www.bussfortog.no/",
    roles: [UserRole.trafikkplanlegger],
  },
  {
    label: "Planday",
    url: "https://id.planday.com/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Ddc19f05b-cdd2-4776-9104-fdc54b5f0ad1%26response_type%3Dcode%26scope%3Dopenid%2520plandayid%26redirect_uri%3Dhttps%253A%252F%252Fnsb.planday.com%252Fauth%252F%26state%3DeyJpZCI6IjE0MjJmMzYzLWRmOTktNDc3MC05MGYyLTRkZTc3MzcwZTJmOSIsInJlZGlyZWN0Q291bnQiOjB9%26acr_values%3Dtenant%253Ansb.planday.com%26ui_locales%3Dnb-NO",
    roles: [UserRole.trafikkplanlegger],
  },
  {
    label: "Tiltak",
    url: "https://tiltak.nsb.no/login#/train_plans",
    roles: [UserRole.trafikkplanlegger],
  },
  {
    label: "Egencia",
    url: "https://www.egencia.com/public/no/",
    roles: [UserRole.trafikkplanlegger],
  },
  {
    label: "Meld feil i Vys kundekanaler",
    url: "https://vygruppen.pureservice.com/#/categories/31",
    roles: [UserRole.trafikkplanlegger],
  },
  {
    label: "Best",
    url: "https://best.jbv.no/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Referansenummer",
    url: "https://referansenummer.vy.no/",
    roles: [
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "Aksjonskort BN",
    url: "https://orv.banenor.no/orv/doku.php?id=sidebar&id=avviksh%C3%A5ndtering:Aksjonskort",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "CCTV",
    url: "http://linuxserver99.nsb.no:8080/webapp",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "FIDO",
    url: " https://fido.banenor.no",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
    ],
  },
  {
    label: "RM",
    url: "http://rm.nsb.no/RM/#!/",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "Tios",
    url: "https://bnfed.banenor.no/adfs/ls?version=1.0&action=signin&realm=urn%3AAppProxy%3Acom&appRealm=07b0a215-705d-e711-80cd-005056a84f51&returnUrl=https%3A%2F%2Ftios.banenor.no%2Ftios2%2Fhjem%2Fforside&client-request-id=1AB90211-A733-0003-FA0F-DE1A33A7D601",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Yr",
    url: "https://www.yr.no/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Send SMS",
    url: "http://live.intouch.no/#/sendSms/ipCheckConfig",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Puzzle (admin)",
    url: "https://admin.puzzel.com/admin",
    roles: [UserRole.vaktleder],
  },
  {
    label: "Puzzel (agent)",
    url: "https://agent.puzzel.com/",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "FIDO ",
    url: "https://fido.banenor.no",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Meld fravær i DROPS",
    url: "mailto:Tjenestekontor_Oslo@vy.no?subject=Fravær%20i%20DROPS&body=Når%3A%20dd.mm%0D%0AHvem%3A%0D%0AMelding%20til%20TK%3A",
    roles: [
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
    ],
  },
  {
    label: "Trafikkmeldinger BaneNOR",
    url: "https://www.banenor.no/Nyheter/trafikkmeldinger/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "AT-melding BN",
    url: "https://atmeldinger.banenor.no",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "RM VY TÅG",
    url: "http://rm.vy.se/RM/#!/",
    roles: [UserRole.vaktleder, UserRole.transportleder],
  },
  {
    label: "Jobbportalen",
    url: "http://jobbportalen.vy.no/",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Kvitteringsliste",
    url: "https://checklist.vy.no/",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Tjenesterapport",
    url: "https://rapp.nsb.no/clientReport/register/37",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "CIM",
    url: "https://vy-cim.no/",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "Sharepoint",
    url: "https://nsbas.sharepoint.com/sites/Vytrafikkogteknikk/DROPS/lokleder/SitePages/Hjemmeside.aspx",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "Aksjonskort",
    url: "https://orv.banenor.no/orv/doku.php?id=sidebar&id=avviksh%C3%A5ndtering:Aksjonskort",
    roles: [UserRole.lokleder1, UserRole.lokleder2],
  },
  {
    label: "LOS/EK",
    url: "https://vytog.datakvalitet.net/portal.aspx",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
    ],
  },
  {
    label: "WIKI Trafikkplanlegger",
    url: "https://vygruppen.atlassian.net/wiki/spaces/TPWN/overview",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "WIKI Vaktleder",
    url: "https://vygruppen.atlassian.net/wiki/spaces/VW",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "WIKI Personellstyrer",
    url: "https://vygruppen.atlassian.net/wiki/spaces/PW",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "WIKI Materiellstyrer",
    url: "https://vygruppen.atlassian.net/wiki/spaces/MW",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
      UserRole.materiellstyrerlokal,
      UserRole.materiellstyrerregion,
      UserRole.personellstyrerkond,
      UserRole.personellstyrerlok,
      UserRole.personellstyrervest,
      UserRole.trafikkplanlegger,
    ],
  },
  {
    label: "TOPAS/Ordrekontroll",
    url: "https://portal.personell.vy.no",
    roles: [
      UserRole.vaktleder,
      UserRole.transportleder,
      UserRole.lokleder1,
      UserRole.lokleder2,
    ],
  },
];
