import {
  DropdownUpFill24Icon,
  DropdownDownFill24Icon,
} from "@vygruppen/spor-icon-react";
import { IconButtonWithTransition } from "shared/components/buttons/IconButtonWithTransition";
import styled from "styled-components";

const ChevronIconUpStyled = styled(DropdownUpFill24Icon)<{
  $active: boolean;
}>`
  ${({ $active }) => $active && `transform: rotate(180deg)`}
`;

const ChevronIconDownStyled = styled(DropdownDownFill24Icon)<{
  $active: boolean;
}>`
  ${({ $active }) => $active && `transform: rotate(180deg)`}
`;

export const ChevronUpButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: any;
}) => (
  <IconButtonWithTransition
    variant="tertiary"
    active={active}
    setActive={setActive}
  >
    <ChevronIconUpStyled $active={active} />
  </IconButtonWithTransition>
);

export const ChevronDownButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: any;
}) => (
  <IconButtonWithTransition
    variant={active ? "tertiary" : "secondary"}
    active={active}
    setActive={setActive}
  >
    <ChevronIconDownStyled $active={active} />
  </IconButtonWithTransition>
);
