import { Button, ButtonProps } from "@vygruppen/spor-react";
import React, { FC, ReactElement } from "react";
import { getButtonStylePrimary } from "shared/components/buttons/SemanticButtonPrimary";
import { getButtonStyleTertiary } from "shared/components/buttons/SemanticButtonTertiary";
import { Severity } from "shared/components/feedback/InfoMessage/severity";
import { useTheme } from "styled-components";

export type ButtonSeverity = "info" | "alarm" | "secondaryAlarm" | "warning";

export const toButtonSeverity = (severity: Severity) => {
  switch (severity) {
    case "info":
      return "info";
    case "success":
      throw Error("not mapped");
    case "error":
      return "alarm";
    case "warning":
      return "warning";
    default:
      throw Error(`unhandled severity ${severity}`);
  }
};

export type ButtonColors = {
  severityTextMain: string;
  severity: string;
  severityFill: string;
  severityFillPulse: string;
};

interface SemanticButtonProps extends ButtonProps {
  severity?: ButtonSeverity;
  size?: "xs" | "sm" | "md";
  isDisabled?: boolean;
  variant?: "primary" | "tertiary";
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

export const SemanticButton: FC<SemanticButtonProps> = ({
  severity,
  size = "xs",
  leftIcon,
  rightIcon,
  isDisabled,
  variant = "tertiary",
  ...props
}) => {
  const theme = useTheme();
  const getButtonStyle =
    variant === "primary" ? getButtonStylePrimary : getButtonStyleTertiary;
  return (
    <Button
      size={size}
      variant={variant}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isDisabled={isDisabled}
      {...props}
      sx={
        severity
          ? getButtonStyle(severity, theme)
          : { backgroundColor: theme.colorBackgroundSecondary }
      }
    >
      {props.children}
    </Button>
  );
};
