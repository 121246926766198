import { FC } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

type MapAlertProps = {
  isError: boolean;
  hasNoResults: boolean;
  query: string;
};

const MapAlert: FC<MapAlertProps> = ({ isError, hasNoResults, query }) => {
  if (hasNoResults) {
    return (
      <DropsStaticAlert variant="error">
        Fant ingen materiell med ID &quot;{query}&quot;
      </DropsStaticAlert>
    );
  }
  if (isError) {
    return (
      <DropsStaticAlert variant="error">
        Noe gikk galt ved innlasting av data
      </DropsStaticAlert>
    );
  }

  return null;
};

export default MapAlert;
