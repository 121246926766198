import React, { ReactElement } from "react";
import {
  SemanticButton,
  toButtonSeverity,
} from "shared/components/buttons/SemanticButton";
import { Severity } from "shared/components/feedback/InfoMessage/severity";
import styled, { css } from "styled-components";

export type ActionButton = {
  title: string;
  isDisabled?: boolean;
  onClick: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  isLoading?: boolean;
  variant?: "primary" | "tertiary";
};

const ActionGroup = styled.div<{ $rightAlign: boolean }>`
  gap: 6px;

  width: 100%;

  ${({ $rightAlign }) =>
    $rightAlign
      ? css`
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(2, auto) 1fr;
        `}
`;

interface ActionButtonsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  actionButtons: ActionButton[];
  severity: Severity;
  rightAlign?: boolean;
}

export const ActionButtons = ({
  actionButtons,
  severity,
  rightAlign = false,
  ...rest
}: ActionButtonsProps) => (
  <ActionGroup $rightAlign={rightAlign} role="group" {...rest}>
    {actionButtons.map((button) => (
      <SemanticButton
        severity={toButtonSeverity(severity)}
        key={button.title}
        size="xs"
        variant={button.variant}
        isDisabled={button.isDisabled}
        onClick={button.onClick}
        leftIcon={button.leftIcon}
        rightIcon={button.rightIcon}
      >
        {button.title}
      </SemanticButton>
    ))}
  </ActionGroup>
);
