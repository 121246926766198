import { WarningOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, Stack, Text } from "@vygruppen/spor-react";
import { GlobalEventModalContent } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModalContent";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { EditGlobalEventChekcbox } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/EditGlobalEventChekcbox";
import { FC, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ChevronDownButton } from "shared/components/buttons/ChevronButton";
import { useTheme } from "styled-components";

type EditGlobalEventProps = {
  globalEventFormMethods: UseFormReturn<GlobalFormSchema>;
  editGlobalEvent: boolean;
  setEditGlobalEvent: (value: boolean) => void;
};

export const EditGlobalEvent: FC<EditGlobalEventProps> = ({
  globalEventFormMethods,
  editGlobalEvent,
  setEditGlobalEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      gap={4}
      paddingX={3}
      paddingY={2}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      <HStack justifyContent="space-between">
        <HStack>
          <WarningOutline18Icon />
          <Text variant="xs" fontWeight="bold">
            Global driftsmelding
          </Text>
        </HStack>
        <ChevronDownButton active={isOpen} setActive={setIsOpen} />
      </HStack>
      {isOpen && (
        <>
          <EditGlobalEventChekcbox
            editGlobalEvent={editGlobalEvent}
            setEditGlobalEvent={setEditGlobalEvent}
          />
          <GlobalEventModalContent formMethods={globalEventFormMethods} />
        </>
      )}
    </Stack>
  );
};
