import * as z from "zod";
import {
  alternativeTransportResponseSchema,
  AlternativeTransportVehicle,
} from "shared/types/alternativeTransport";
import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "../../../../api/http/config";
import { getBackendUrl } from "../../../../api/common";

export const isShuttleVehicle = (
  vehicle: AlternativeTransportVehicle,
): boolean =>
  vehicle.serviceType === "OPERATIONAL" && vehicle.isPartOfShuttlePlan;

export const useAlternativeTransportData = (vehicleUuid?: string) =>
  useQuery({
    queryKey: ["altTransDetails", vehicleUuid],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      axiosClient
        .get<
          z.infer<typeof alternativeTransportResponseSchema>
        >(`${getBackendUrl()}/alternativeTransports/${vehicleUuid}`, { signal })
        .then((res) => alternativeTransportResponseSchema.parse(res.data)),
  });
