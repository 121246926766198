import { Tooltip } from "@chakra-ui/react";
import { EMailOutline18Icon } from "@vygruppen/spor-icon-react";
import { Divider, Flex, Text } from "@vygruppen/spor-react";
import { Link } from "react-router-dom";
import { VisibilityIndicator } from "shared/icons/VisibilityIndicator";
import { capitalize } from "shared/utils/objectUtils";
import styled from "styled-components";
import { ServiceStatus, alertLevelToSemantics } from "./SystemStatus";

const SystemStatusContainer = styled.div`
  display: flex;
  width: 306px;
  height: 290px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorOutline};
  border-radius: 6px;
  box-sizing: border-box;
  z-index: 1;
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const FlexHover = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.colorSuccessFill};
  }
  flex: 1;
  height: 36px;
  border-radius: 999px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const SystemStatusModal = ({ data }: { data: ServiceStatus[] }) => (
  <div
    style={{
      marginTop: "48px",
      marginLeft: "-30px", // Reach the divider
      position: "absolute",
    }}
  >
    <SystemStatusContainer>
      <Link to="https://vyutv.pagerduty.com/service-directory/">
        <Text m="12px" fontWeight={900} flex="1">
          Systemstatus
        </Text>
      </Link>
      <Divider />
      <Flex
        py="12px"
        px="6px"
        flexDirection="column"
        overflowY="scroll"
        gap="6px"
      >
        {data.map((status, index) => (
          <Flex key={index} alignItems="center" gap="12px">
            <Tooltip hasArrow label={status.description}>
              <StyledLink
                to={`mailto:${status.mail}?cc=team-brukerflater-drops@vy.no,itservicesenter@service-now.com&subject=Service%20is%20down%3A%20${status.name}`}
              >
                <FlexHover
                  justifyContent="space-between"
                  alignItems="center"
                  cursor="pointer"
                >
                  <Flex gap="12px">
                    <VisibilityIndicator
                      semantics={alertLevelToSemantics[status.status]}
                    />
                    <Text>{capitalize(status.name.replace("-", " "))}</Text>
                  </Flex>
                  <EMailOutline18Icon />
                </FlexHover>
              </StyledLink>
            </Tooltip>
          </Flex>
        ))}
      </Flex>
    </SystemStatusContainer>
  </div>
);
