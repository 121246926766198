import { Tooltip } from "@chakra-ui/react";
import { InformationOutline24Icon } from "@vygruppen/spor-icon-react";
import { Box, HStack, SkeletonText, Text } from "@vygruppen/spor-react";
import { FC } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

export type PreviewStatus = "pending" | "success" | "error" | "idle";

type PreviewProps = {
  previewStatus: PreviewStatus;
  previewedText: string;
};

const PreviewContent: FC<PreviewProps> = ({ previewStatus, previewedText }) => {
  switch (previewStatus) {
    case "idle":
      return <Text variant="xs">En oppsummering av hendelsen.</Text>;
    case "pending":
      return <SkeletonText noOfLines={3} width="100%" />;
    case "success":
      return <Text variant="xs">{previewedText}</Text>;
    case "error":
    default:
      return (
        <DropsStaticAlert variant="error">
          Kunne ikke hente oppsummering
        </DropsStaticAlert>
      );
  }
};

export const Preview: FC<PreviewProps> = (props) => (
  <Box>
    <HStack>
      <Text variant="xs" fontWeight="bold">
        Oppsummering
      </Text>
      <Tooltip
        hasArrow
        label="Dette er en oppsummering av informasjon som sendes ut om denne hendelsen. Ordlyden tilpasses etter mottaker."
        placement="right"
      >
        <InformationOutline24Icon />
      </Tooltip>
    </HStack>
    <PreviewContent {...props} />
  </Box>
);
