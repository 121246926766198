import { useToast } from "@vygruppen/spor-react";
import { useInterval } from "app/Auth/NetworkError";
import { ONE_DAY_IN_MS } from "features/CenterContent/RoleContent/VehicleMap/constants";
import {
  clearAllToasts,
  useOnlineStatus,
} from "features/CenterContent/shared/utils";
import React, { ReactNode, useCallback, useRef, useState } from "react";

interface NetworkToastContextValue {
  sendNetworkToast: () => void;
}

const initialValue: NetworkToastContextValue = {
  sendNetworkToast: () => {},
};

const NetworkToastContext = React.createContext(initialValue);

/**
 * Sends a toast if there are no network connection
 */
const NetworkToastProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const [, setShowingToast] = useState(false);
  const timerRef = useRef<number | null>(null);
  const isOnline = useOnlineStatus();

  // Sends a Network Toast that lasts for 10 seconds. If one is already showing, reset timer to 10 seconds.
  const sendNetworkToast = useCallback(() => {
    setShowingToast((prev) => {
      if (!prev) {
        toast({
          variant: "error",
          text: "Det er problemer med tilkoblingen til serveren. Vennligst kontroller din nettverksforbindelse, eller last inn siden på nytt.",
          duration: ONE_DAY_IN_MS,
        });
        return true;
      }
      return prev;
    });
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      setShowingToast(false);
      clearAllToasts();
    }, 10000);
  }, []);

  // Send a Network Toast every 5 second if !IsOnline
  useInterval(sendNetworkToast, isOnline ? null : 5000);

  const value = { sendNetworkToast };
  return (
    <NetworkToastContext.Provider value={value}>
      {children}
    </NetworkToastContext.Provider>
  );
};

const useNetworkToast = () => React.useContext(NetworkToastContext);

export { NetworkToastProvider, useNetworkToast };
