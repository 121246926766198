import { Stack, Text, VStack } from "@vygruppen/spor-react";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { CommonSubTypeProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { EitherTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  ALTERNATIVE_STRETCHES,
  Stretch,
  formatStretchName,
} from "features/CenterContent/shared/operationalInformation/alternativeStretches";
import {
  affectedstopsMatchStopsInStretch,
  shouldFlipStretchDirection,
  findAlternativeStretch,
  eventAlreadyExists,
  isRelevantStretch,
  flipIfShouldFlip,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/changedRouteUtils";
import { TrainContext } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { useRouteChangedEvents } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { FC, useEffect, useMemo, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { Select } from "shared/components/forms/Select";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { useBatchUseTrainRoute } from "../useTrainInfoContext";

export const TrainChangedRoute: FC<
  CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext
> = ({ trainId, uuid, formContext, trainFormPrefix }) => {
  // Cast context fields, the default from react-hook-form isn't good enough
  const { errors } = formContext.formState;
  const getValues =
    formContext.getValues as UseFormGetValues<EitherTrainFormSchema>;
  const setValue =
    formContext.setValue as UseFormSetValue<EitherTrainFormSchema>;

  // Define all field paths
  const originalStretchNameFieldPath =
    `${trainFormPrefix}.originalRouteName` as const;
  const newStretchNameFieldPath = `${trainFormPrefix}.newRouteName` as const;
  const affectedStopsFieldPath = `${trainFormPrefix}.affectedStops` as const;
  const cancelledStopsFieldPath = `${trainFormPrefix}.cancelledStops` as const;
  const newRouteFieldPath = `${trainFormPrefix}.newRoute` as const;

  const batchUseTrainRoute = useBatchUseTrainRoute();
  const { data: trainRoute, status: fetchStatus } = batchUseTrainRoute(trainId);

  const { data: existingEvents } = useRouteChangedEvents(trainId);

  // Handle internal state for select
  const [newStretchIndex, setNewStretchIndex] = useState<number>(() =>
    ALTERNATIVE_STRETCHES.findIndex(
      (stretch) =>
        stretch.name === getValues(originalStretchNameFieldPath) &&
        affectedstopsMatchStopsInStretch(
          getValues(affectedStopsFieldPath) ?? [],
          stretch.stops,
        ),
    ),
  );
  const [replacedStretch, setReplacedStretch] = useState<Stretch | undefined>();
  const [newStretch, setNewStretch] = useState<Stretch | undefined>();

  // Reset index when switching tabs in modal
  useEffect(() => {
    const existingValue = ALTERNATIVE_STRETCHES.findIndex(
      (stretch) =>
        stretch.name === getValues(originalStretchNameFieldPath) &&
        affectedstopsMatchStopsInStretch(
          getValues(affectedStopsFieldPath) ?? [],
          stretch.stops,
        ),
    );
    setNewStretchIndex(existingValue);
  }, [trainFormPrefix]);

  // Set new and replaced stretch to match index
  useEffect(() => {
    if (newStretchIndex !== -1) {
      setNewStretch(ALTERNATIVE_STRETCHES[newStretchIndex]);
      setReplacedStretch(
        findAlternativeStretch(ALTERNATIVE_STRETCHES[newStretchIndex]),
      );
    } else {
      setNewStretch(undefined);
      setReplacedStretch(undefined);
    }
  }, [newStretchIndex]);

  // Keep other fields in sync with the selected stretch
  useEffect(() => {
    // Empty form state if no stretch is selected
    if (newStretchIndex === -1) {
      setValue(originalStretchNameFieldPath, "");
      setValue(newStretchNameFieldPath, "");
      setValue(cancelledStopsFieldPath, []);
      setValue(affectedStopsFieldPath, []);
      setValue(newRouteFieldPath, []);
      return;
    }

    // Stretch index is set but replaced and new stretch haven't been autofilled yet
    // do nothing until they have values
    if (!replacedStretch || !newStretch) return;

    // Stretches are selected, autofill the rest of the form state
    setValue(originalStretchNameFieldPath, replacedStretch.name);
    setValue(newStretchNameFieldPath, newStretch.name);

    // Affected stops are unidirectional but the trains may be running the other
    // direction. If so, we flip the affected stops array to match the route.
    const shouldFlip = shouldFlipStretchDirection(replacedStretch, trainRoute);

    // Stops that are in the old route but not the new one
    const cancelledStops = flipIfShouldFlip(
      shouldFlip,
      replacedStretch.stops.filter((stop) => !newStretch.stops.includes(stop)),
    );
    setValue(cancelledStopsFieldPath, cancelledStops, {
      shouldValidate: true,
    });

    // All stops in the old stretch are regarded as affected
    const affectedStops = flipIfShouldFlip(shouldFlip, replacedStretch.stops);
    setValue(affectedStopsFieldPath, affectedStops, {
      shouldValidate: true,
    });

    // The full route of the train, with the affected stretch replaced by
    // the new one
    const trainRouteStops = trainRoute?.stops.map((stop) => stop.stopId) ?? [];
    const firstAffectedStopIndex = trainRouteStops.findIndex(
      (stop) => stop === affectedStops[0],
    );
    const lastAffectedStopIndex = trainRouteStops.findIndex(
      (stop) => stop === affectedStops.at(-1),
    );
    const numberOfStopsToReplace =
      lastAffectedStopIndex - firstAffectedStopIndex + 1; // Inclusive of last stop
    const stopsInNewRoute = flipIfShouldFlip(shouldFlip, newStretch.stops);
    const newRoute = trainRouteStops.toSpliced(
      firstAffectedStopIndex,
      numberOfStopsToReplace,
      ...stopsInNewRoute,
    );
    setValue(newRouteFieldPath, newRoute, {
      shouldValidate: true,
    });
  }, [newStretchIndex, newStretch, replacedStretch, trainRoute]);

  const sameStretchAlreadySelected = useMemo(
    () =>
      newStretch
        ? eventAlreadyExists(newStretch, existingEvents ?? [], uuid)
        : false,
    [newStretch, existingEvents, uuid],
  );

  return (
    <Stack>
      <Text fontWeight="bold">Gammel rute:</Text>
      <Text pb="1rem">
        {replacedStretch
          ? formatStretchName(
              replacedStretch,
              shouldFlipStretchDirection(replacedStretch, trainRoute),
            )
          : "Ikke valgt"}
      </Text>
      <VStack width="100%" gap={2} alignItems="flex-start">
        <Select
          label="Ny rute"
          value={newStretchIndex}
          onChange={(e) => {
            setNewStretchIndex(Number(e.currentTarget.value));
          }}
        >
          <option value={-1}>Velg en strekning</option>
          {ALTERNATIVE_STRETCHES.map((stretch, i) => (
            <option
              key={i}
              value={i}
              disabled={
                fetchStatus !== "error" &&
                !isRelevantStretch(stretch, trainRoute)
              }
            >
              {formatStretchName(
                stretch,
                shouldFlipStretchDirection(stretch, trainRoute),
              )}
            </option>
          ))}
        </Select>
        <RenderErrorInPath
          errors={errors}
          errorPath={newStretchNameFieldPath}
        />
      </VStack>

      {fetchStatus === "error" && (
        <DropsStaticAlert variant="warning">
          Kunne ikke hente rutedata. Listen med omkjøringer blir ikke filtrert
          automatisk. Pass på at du velger riktig omkjøring.
        </DropsStaticAlert>
      )}

      {sameStretchAlreadySelected && (
        <DropsStaticAlert variant="warning">
          Samme trasé er valgt i en annen hendelse
        </DropsStaticAlert>
      )}
    </Stack>
  );
};
