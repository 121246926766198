import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EditOutline24Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { DropsLogTextInput } from "features/CenterContent/RoleContent/AffectedTrains/DropsLogTextInput";
import { supportedOpInfoTypes } from "features/CenterContent/shared/operationalInformation/supportedOpInfoTypes";
import { EditTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/EditTrainInfoModal";
import { TrainInfoVersions } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoVersions";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import {
  TrainInformationAggregated,
  TrainInfoMessage as TrainInfoMsg,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { getUrgencySeverityMapping } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { DetailsParams } from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import { routeChangedEventsQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ActionButton } from "shared/components/feedback/InfoMessage/ActionButtons";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { formatDayDateString } from "shared/utils/datetime";
import styled from "styled-components";

const Versions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 12px 30px 0 0;
`;

type TrainInfoMessageProps = {
  info: TrainInformationAggregated;
  trainInfoWithOpenState: string[];
};

export const TrainInfoMessage: FC<TrainInfoMessageProps> = ({
  info,
  trainInfoWithOpenState,
}) => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();
  const queryClient = useQueryClient();

  const [dropsLogText, setDropsLogText] = useState<string | null>(null);
  const { mutate: closeTrainStoppedStatus } = useMutation({
    mutationKey: ["closeTrainStoppedStatus", info.latestInfoUuid],
    mutationFn: () =>
      mutationFnPOST<string, { dropsLogText?: string }>(
        `${getBackendUrl()}/trainInformation/information/${info.latestInfoUuid}/close`,
        dropsLogText ? { dropsLogText } : {},
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [routeChangedEventsQueryKey, trainNumber, trainDate],
      });
    },
  });

  const [showSpinner, setShowSpinner] = useState(false);
  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);

  const indexOfLatestInfoUuid = info.versions.findIndex(
    (version) => version.uuid === info.latestInfoUuid,
  );

  // For some event types (TRAIN_STOPPED, TRAIN_INCREASED_CAPACITY, TRAIN_REDUCED_CAPACITY, TRAIN_MISSING_PRODUCT), multiple events for the same train is not supported.
  // Creating a new event will be considered a new version of the previous event of the same type, even if it is closed or replaced.
  // This can in some edge cases lead to all events being considered related to each other, leading to duplicate info lists with too many versions.
  // Therefore, we need to ensure that the the newest version uuid matches latestInfoUuid.
  // This is to ensure that all non-replaced versions are displayed on top, so that they can be closed or edited.
  const infoWithAllVersionsUpToLatestInfoUuid = {
    ...info,
    versions: info.versions.slice(indexOfLatestInfoUuid),
  };

  const newestVersion = infoWithAllVersionsUpToLatestInfoUuid.versions[0];

  useEffect(() => {
    setShowSpinner(false);
    setAskConfirmation(false);
  }, [info.versions.length]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const actionButtons: ActionButton[] = [
    {
      title: "Friskmeld",
      isLoading: showSpinner,
      onClick: () => {
        setAskConfirmation(true);
      },
      variant: "primary",
    },
    {
      title: "Endre",
      onClick: () => setModalOpen(true),
      leftIcon: <EditOutline24Icon />,
      variant: "tertiary",
    },
  ];

  const confirmActionButtons: ActionButton[] = [
    {
      title: "Ja, friskmeld",
      variant: "primary",
      isLoading: showSpinner,
      onClick: () => {
        setShowSpinner(true);
        closeTrainStoppedStatus();
      },
    },
    { title: "Avbryt", onClick: () => setAskConfirmation(false) },
  ];

  function getActionButtons(): ActionButton[] {
    if (
      newestVersion.state === "OPEN" &&
      (supportedOpInfoTypes.includes(newestVersion.type) ||
        newestVersion.type === "TRAIN_GENERAL_INFO" ||
        newestVersion.type === "TRAIN_GENERAL_WARNING")
    ) {
      if (askConfirmation) {
        return confirmActionButtons;
      }
      return actionButtons;
    }
    return [];
  }

  const getTitle = (trainInfoMessage: TrainInfoMsg): string => {
    switch (trainInfoMessage.state) {
      case "CLOSED":
        return `${trainInfoMessage.title} [friskmeldt]`;
      case "DEPUBLISHED":
        return `${trainInfoMessage.title} [depublisert]`;
      case "REPLACED":
        return `${trainInfoMessage.title} [erstattet av ny hendelse]`;
      default:
        return trainInfoMessage.title;
    }
  };

  if (trainNumber === undefined || trainDate === undefined) {
    return (
      <FailureMessage customMessage="Kunne ikke hente tognummer eller dato for dette toget." />
    );
  }

  return (
    <ExpandableInfoMessage
      style={{ width: "100%" }}
      key={info.latestInfoUuid}
      severity={getUrgencySeverityMapping(newestVersion)}
      title={getTitle(newestVersion)}
      subTitle={formatDayDateString(newestVersion.updatedAt)}
      actionButtons={getActionButtons()}
      hideSubtitleWhileOpen
    >
      <Versions>
        {askConfirmation && (
          <Text variant="xs" fontWeight="bold">
            Er du sikker på at du vil friskmelde hendelsen?
          </Text>
        )}
        <TrainInfoVersions
          severity={getUrgencySeverityMapping(newestVersion)}
          trainInfo={infoWithAllVersionsUpToLatestInfoUuid}
        />
        {askConfirmation && (
          <DropsLogTextInput
            dropsLogText={dropsLogText}
            setDropsLogText={setDropsLogText}
            uuid={info.latestInfoUuid}
            defaultEnabled={
              // We almost always want "Tog står" to go to drops log
              newestVersion.type === TrainEventTypeEnum.TRAIN_STOPPED
            }
          />
        )}
      </Versions>

      {modalOpen && (
        <EditTrainInfoModal
          uuid={info.latestInfoUuid}
          setModalOpen={setModalOpen}
          trainId={trainNumber}
          nominalDate={trainDate}
          countryCode="NO" // TODO: CountryCode should not be hardcoded
          trainInfoWithOpenState={trainInfoWithOpenState}
        />
      )}
    </ExpandableInfoMessage>
  );
};
