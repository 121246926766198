import { Environment, environment } from "environment";
import { WebsocketClientProps } from "websocket/useWebsocketClient";
import { FIVE_SECONDS, ONE_MINUTE } from "websocket/utils";

const getDropsWebsocketUrl = (): string => {
  const env = environment();

  switch (env) {
    case Environment.Development:
    case Environment.Test:
      return `wss://websocket.test.trafficgui.vydev.io/drops-backend`;
    case Environment.Stage:
      return `wss://websocket.stage.trafficgui.vydev.io/drops-backend`;
    case Environment.Production:
      return `wss://websocket.trafficgui.vydev.io/drops-backend`;
    default:
      throw new Error(`getDropsWebsocketUrl: Unknown environment ${env}`);
  }
};

export const DROPS_WS_PROPERTIES: WebsocketClientProps = {
  url: getDropsWebsocketUrl(),
  pingInterval: ONE_MINUTE,
  pongTimeout: FIVE_SECONDS,
};
