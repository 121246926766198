import { zodResolver } from "@hookform/resolvers/zod";
import { FilterOutline24Icon } from "@vygruppen/spor-icon-react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  IconButton,
  Text,
} from "@vygruppen/spor-react";
import { getLocalStorageTodoFilterSettings } from "api/dropsLocalStorage";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { InputTextarea } from "features/TodoOplogg/common/InputTextarea";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";
import { getDefaultReceivingRoles } from "features/TodoOplogg/common/utils";
import { TodoForm, todoForm } from "features/TodoOplogg/formSchema";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { dropsRole } from "stores/dropsRole";
import { useTheme } from "styled-components";
import TodoComponent from "./TodoComponent";
import { RoleOptions } from "./TodoInput/RoleOptions";
import { VehicleSetOptions } from "./TodoInput/VehicleSetOptions";
import { CircleIndicator, FilterButtonWrapper, TopFlex } from "./TodoStyles";
import TodoFilter from "./todoFilter/TodoFilter";
import useTodoFilterState, {
  defaultTodoFilterState,
  isDefault,
} from "./todoFilter/useTodoFilterState";
import { usePost, usePostStatus } from "./useTodoContext";

const Todo = () => {
  const { role: currentRole } = dropsRole();

  const [textareaExpanded, setTextareaExpanded] = useState(true);
  const [filter, setFilter] = useState(false);
  const [messaging, setMessaging] = useState(false);

  const post = usePost();
  const postStatus = usePostStatus();

  const theme = useTheme();

  const {
    todoFilterState,
    setTodoFilterState,
    toggleRoles,
    toggleVehicleSetTypes,
    toggleTodoStatus,
  } = useTodoFilterState(getLocalStorageTodoFilterSettings());

  const formMethods = useForm<TodoForm>({
    resolver: zodResolver(todoForm),
    defaultValues: {
      description: "",
      roles: getDefaultReceivingRoles(currentRole),
    },
  });

  const toggleView = (view: string) => () => {
    setFilter(view === "filter" ? !filter : false);
    setMessaging(view === "messaging" ? !messaging : false);
    setTextareaExpanded(true);
  };

  const { reset, handleSubmit } = formMethods;

  const onSubmit = handleSubmit((data) => {
    const roles = data.roles || [];
    const vehicleSetTypes = data.vehicleSetTypes || [];

    const todoData = {
      description: data.description,
      roles,
      vehicleSetTypes: vehicleSetTypes.join(","),
    };

    post(todoData);
    reset();
  });

  return (
    <Flex flexDir="column" overflowY="auto">
      <TopFlex>
        <Text fontWeight="bold">Todo</Text>
        <Flex>
          <Button
            onClick={toggleView("messaging")}
            style={{ marginLeft: "120px", marginRight: "10px" }}
          >
            Ny melding
          </Button>
          <FilterButtonWrapper>
            <IconButton
              name="filter-button-todo"
              role="button"
              data-testid="filter-button-todo"
              aria-label="Søk"
              variant="tertiary"
              onClick={toggleView("filter")}
              icon={<FilterOutline24Icon />}
              style={{
                backgroundColor: filter
                  ? theme.searchFilterActive
                  : "transparent", // Bright background when filter is active
              }}
            />
            {!isDefault(todoFilterState, currentRole) && <CircleIndicator />}
          </FilterButtonWrapper>
        </Flex>
      </TopFlex>

      <FormProvider {...formMethods}>
        <ShowIfTrue show={messaging}>
          <Divider />

          <form
            onSubmit={onSubmit}
            style={{ padding: "20px", position: "relative" }}
          >
            <FormControl>
              <Text fontWeight="bold" paddingBottom={1}>
                Ny melding
              </Text>
              <InputTextarea
                label="Skriv inn tekst her"
                formField="description"
              />
            </FormControl>
            <ShowIfTrue show={textareaExpanded}>
              <Flex flexDirection="column" gap={2}>
                <RoleOptions />
                <VehicleSetOptions />
              </Flex>
              <Flex marginTop="20px" alignItems="center">
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  isLoading={postStatus === "pending"}
                  ml="auto"
                  data-testid="submit-button-todo"
                  onClick={() => {
                    setTimeout(() => {
                      setMessaging(false);
                    }, 500); // Delay of 0.5 sec
                  }}
                >
                  Send
                </Button>
              </Flex>
            </ShowIfTrue>
          </form>
        </ShowIfTrue>

        <ShowIfTrue show={filter}>
          <Divider />

          <TodoFilter
            todoFilterState={todoFilterState}
            setTodoFilterState={setTodoFilterState}
            clearTodoFilterState={() =>
              setTodoFilterState({
                ...defaultTodoFilterState,
                roles: [currentRole],
              })
            }
            toggleRoles={toggleRoles}
            toggleVehicleSetTypes={toggleVehicleSetTypes}
            toggleTodoStatus={toggleTodoStatus}
          />
        </ShowIfTrue>
      </FormProvider>
      <Divider />

      <TodoComponent
        todoFilterState={todoFilterState}
        setTodoFilterState={setTodoFilterState}
      />
    </Flex>
  );
};

export default withErrorBoundary(Todo);
