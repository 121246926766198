import { Button, Td, Text, Tr } from "@vygruppen/spor-react";
import {
  alarmRowOnClick,
  getMappedAlertState,
  getMappedAlertType,
} from "features/CenterContent/shared/TilsynAlerts/utils";
import { formatDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";
import { VehicleAlert } from "features/CenterContent/shared/TilsynAlerts/schema";

const CustomTr = styled(Tr)<{ $hoverBackgroundColor: string }>`
  &&&:hover {
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
  }
  td {
    border: none !important;
  }
`;

type TilsynAlertRowProps = {
  alert: VehicleAlert;
  fullSize: boolean;
};

const TilsynAlertRow = ({ alert, fullSize = false }: TilsynAlertRowProps) => {
  const theme = useTheme();

  const alertTime = formatDateString(
    alert.createdAt,
    "dd.MM.yyyy HH:mm",
    "Ukjent datoformat",
  );
  const actionTime = formatDateString(
    alert.inProgressAt,
    "dd.MM.yyyy HH:mm",
    "Ikke påbegynt",
  );

  const getStateColor = () => {
    switch (alert.state) {
      case "DELAYED":
      case "PENDING":
        return {
          fill: theme.colorWarningFill,
          hover: theme.colorWarningFillPulse,
          text: theme.colorWarningTextMain,
          border: theme.colorWarningOutline,
        };
      case "ACTIVE":
        return {
          fill: theme.colorAlarmFill,
          hover: theme.colorAlarmFillPulse,
          text: theme.colorAlarmTextMain,
          border: theme.colorAlarmOutline,
        };
      case "RESOLVE_IN_PROGRESS":
      default:
        return {
          fill: "inherit",
          hover: theme.colorHoverItem,
          text: "inherit",
          border: theme.colorSeparationLine,
        };
    }
  };

  return (
    <CustomTr
      $hoverBackgroundColor={getStateColor().hover}
      backgroundColor={getStateColor().fill}
      color={getStateColor().text}
      borderBottom={`1px solid ${getStateColor().border}`}
      onClick={() => alarmRowOnClick(alert)}
      cursor="pointer"
    >
      {fullSize && <Td>{alert.setId}</Td>}
      <Td>{alert.vehicleId}</Td>
      <Td>{alertTime}</Td>
      <Td>{getMappedAlertType(alert)}</Td>
      <Td>{actionTime}</Td>
      {fullSize && <Td>{getMappedAlertState(alert)}</Td>}

      {fullSize && (
        <Td>
          <Button
            title="Åpne i Tilsyn"
            size="xs"
            variant="tertiary"
            onClick={() => alarmRowOnClick(alert)}
          >
            <Text variant="xs" color={getStateColor().text}>
              Åpne
            </Text>
          </Button>
        </Td>
      )}
    </CustomTr>
  );
};

export default TilsynAlertRow;
