import { useQuery } from "@tanstack/react-query";
import { Flex, IconButton, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { useState } from "react";
import { Semantics } from "shared/components/badge/DropsBadge";
import { useOnOutsideMouseclick } from "shared/hooks/useOnOutsideMouseclick";
import { VisibilityIndicator } from "shared/icons/VisibilityIndicator";
import { SystemStatusModal } from "./SystemStatusModal";

export interface ServiceStatus {
  status: AlertLevel;
  name: String;
  description: String;
  mail: String;
}

export enum AlertLevel {
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
}

const alertLevelToNumber: Record<AlertLevel, number> = {
  [AlertLevel.GREEN]: 0,
  [AlertLevel.YELLOW]: 1,
  [AlertLevel.RED]: 2,
};

export const alertLevelToSemantics: Record<AlertLevel, Semantics> = {
  [AlertLevel.GREEN]: "success",
  [AlertLevel.YELLOW]: "secondary-alarm",
  [AlertLevel.RED]: "alarm",
};

const alertLevelsOrdered = [
  AlertLevel.GREEN,
  AlertLevel.YELLOW,
  AlertLevel.RED,
];

const maxAlertLevel = (data?: ServiceStatus[]) => {
  const alertLevels = data
    ? Object.values(data)
    : [{ status: AlertLevel.GREEN }];
  const maxAlertLevelNumber = Math.max(
    ...alertLevels.map((it) => alertLevelToNumber[it.status]),
  );
  return alertLevelsOrdered[maxAlertLevelNumber];
};

export const SystemStatus = () => {
  const { data, error } = useQuery({
    queryKey: ["systemStatus"],
    queryFn: ({ signal }) =>
      queryFnGET<ServiceStatus[]>({
        signal,
        url: `${getBackendUrl()}/pagerduty`,
      }),
  });
  const [showModal, setShowModal] = useState(false);
  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    setShowModal(false);
  });
  if (error) {
    return <Text width="40px">Noe gikk galt</Text>;
  }
  return (
    <Flex ref={mouseclickRef}>
      <IconButton
        aria-label="system-status-button"
        variant="tertiary"
        icon={
          <VisibilityIndicator
            semantics={alertLevelToSemantics[maxAlertLevel(data)]}
          />
        }
        onClick={() => setShowModal(!showModal)}
      />
      {showModal && data && <SystemStatusModal data={data} />}
    </Flex>
  );
};
