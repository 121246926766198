import { ColorModeScript } from "@chakra-ui/color-mode";
import isPropValid from "@emotion/is-prop-valid";
import { Brand, SporProvider, useColorMode } from "@vygruppen/spor-react";
import { NetworkToastProvider } from "features/CenterContent/shared/NetworkToastProvider";
import { TabsProvider } from "features/CenterContent/shared/Tabs/useTabsContext";
import { ReactElement, ReactNode } from "react";
import { darkTheme } from "shared/theme/darkTheme";
import { lightTheme } from "shared/theme/lightTheme";
import { theme } from "shared/theme/theme";
import { StyleSheetManager, ThemeProvider, WebTarget } from "styled-components";
import { DropsWebsocketProvider } from "websocket/drops/DropsWebsocketContext";

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: WebTarget) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

type AppProps = {
  children: ReactNode;
};

/**
 * Wraps the app with the contexts required for full functionality
 */
export const AppProviders = ({ children }: AppProps): ReactElement | null => (
  <NetworkToastProvider>
    <DropsWebsocketProvider>
      <TabsProvider>{children}</TabsProvider>
    </DropsWebsocketProvider>
  </NetworkToastProvider>
);

const ThemeProviders = ({ children }: AppProps): ReactElement | null => {
  const { colorMode } = useColorMode();
  return (
    <ThemeProvider theme={colorMode === "dark" ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};

/**
 * Wraps the app with style related contexts and global style components. Like SporProvider, ColorModeProvider and ThemeProvider
 */
export const StyleProviders = ({ children }: AppProps): ReactElement | null => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <SporProvider theme={theme} brand={Brand.VyUtvikling}>
      <ColorModeScript initialColorMode="light" />
      <ThemeProviders>{children}</ThemeProviders>
    </SporProvider>
  </StyleSheetManager>
);
