import { Tooltip } from "@chakra-ui/react";
import { InformationOutline24Icon } from "@vygruppen/spor-icon-react";
import { Box, HStack, SkeletonText, Text } from "@vygruppen/spor-react";
import { FC } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { PreviewStatus } from "./Preview";

type PreviewProps = {
  previewStatus: PreviewStatus;
  previewTexts?: string[];
  numberOfPreviews?: number;
  isFormValid: boolean;
};

const PreviewContent: FC<PreviewProps> = ({
  previewStatus,
  previewTexts,
  numberOfPreviews,
  isFormValid,
}) => {
  if (!isFormValid) {
    return <Text variant="xs">En oppsummering av hendelsen.</Text>;
  }

  switch (previewStatus) {
    case "idle":
      return <Text variant="xs">En oppsummering av hendelsen.</Text>;
    case "pending":
      return <SkeletonText noOfLines={numberOfPreviews} width="100%" />;
    case "success":
      return (
        previewTexts &&
        previewTexts.map((text, i) => (
          <Text key={i} mt={2} variant="xs" data-testid="previewMessage">
            {text}
          </Text>
        ))
      );
    case "error":
    default:
      return (
        <DropsStaticAlert variant="error">
          Kunne ikke hente oppsummering
        </DropsStaticAlert>
      );
  }
};

export const PreviewList: FC<PreviewProps> = (props) => (
  <Box mb="24px">
    <HStack>
      <Text variant="xs" fontWeight="bold">
        Oppsummering
      </Text>
      <Tooltip
        hasArrow
        label="Dette er en oppsummering av informasjon som sendes ut om denne hendelsen. Ordlyden tilpasses etter mottaker."
        placement="right"
      >
        <InformationOutline24Icon />
      </Tooltip>
    </HStack>
    <PreviewContent {...props} />
  </Box>
);
