import { EditOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, ButtonGroup, Flex, Text } from "@vygruppen/spor-react";
import { FC, ReactNode } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

type ActionButtonProps = {
  closeStatus: "error" | "idle" | "success" | "pending";
  askConfirmation: boolean;
  setAskConfirmation: () => void;
  closeEvent: () => void;
  editEvent: () => void;
  cancelCloseEvent: () => void;
  disableCloseButton?: boolean;
  children?: ReactNode;
};

export const EventActionButtons: FC<ActionButtonProps> = ({
  closeStatus,
  askConfirmation,
  setAskConfirmation,
  closeEvent,
  editEvent,
  cancelCloseEvent,
  disableCloseButton = false,
  children,
}) => {
  if (closeStatus === "error") {
    return (
      <Flex direction="column" gap={2}>
        {children}
        <DropsStaticAlert gridRow="2" gridColumn="span 2" variant="error">
          Kunne ikke friskmelde hendelsen.
        </DropsStaticAlert>
      </Flex>
    );
  }

  if (closeStatus === "success") {
    return (
      <Flex direction="column" gap={2}>
        {children}
        <DropsStaticAlert gridRow="2" gridColumn="span 2" variant="success">
          Hendelse friskmeldt.
        </DropsStaticAlert>
      </Flex>
    );
  }

  return askConfirmation ? (
    <Flex direction="column" gap={2}>
      <Text gridRow="2" gridColumn="1" fontWeight="bold" variant="xs">
        Er du sikker på at du vil friskmelde hendelsen?
      </Text>
      {children}
      <ButtonGroup
        size="xs"
        height="max-content"
        gridRow="2"
        gridColumn="2"
        justifyContent="end"
      >
        <SemanticButton
          severity="info"
          variant="primary"
          onClick={closeEvent}
          isLoading={closeStatus === "pending"}
          isDisabled={disableCloseButton}
        >
          Ja, friskmeld
        </SemanticButton>
        <SemanticButton
          severity="info"
          variant="tertiary"
          onClick={cancelCloseEvent}
        >
          Avbryt
        </SemanticButton>
      </ButtonGroup>
    </Flex>
  ) : (
    <Flex direction="column" gap={2}>
      {children}
      <ButtonGroup
        size="xs"
        height="max-content"
        gridRow="2"
        gridColumn="2"
        justifyContent="end"
      >
        <Button
          variant="primary"
          onClick={setAskConfirmation}
          isDisabled={disableCloseButton}
        >
          Friskmeld
        </Button>
        <Button
          variant="tertiary"
          leftIcon={<EditOutline24Icon />}
          onClick={editEvent}
        >
          Endre
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
