import styled from "styled-components";
import TrainMap from "features/CenterContent/RoleContent/TrainMap/TrainMap";
import {
  SharedContentGridLayout,
  SharedGridStyle,
} from "features/CenterContent/shared/MainContentGrid";
import DayPlanTrackings from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/DayPlanTrackings";

const Container = styled.div`
  ${SharedContentGridLayout};
  grid-template-rows: 7fr 5fr;
  overflow: auto;
`;

const DayPlanTrackingsContainer = styled.div`
  ${SharedGridStyle};
  overflow: scroll;
  width: 100%;
`;

export const Personellstyrer = () => (
  <Container>
    <TrainMap />
    <DayPlanTrackingsContainer>
      <DayPlanTrackings />
    </DayPlanTrackingsContainer>
  </Container>
);
