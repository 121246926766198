import { Text } from "@vygruppen/spor-react";
import { usePostReceipt } from "features/TodoOplogg/Oplogg/useOploggContext";
import { getAbbreviationForRole } from "features/TodoOplogg/common/utils";
import React, { FC } from "react";
import UserCircleCheck from "shared/icons/user-circle-check.svg?react";
import UserCircleDashed from "shared/icons/user-circle-dashed.svg?react";
import { ROLE_TEXTS } from "shared/types/roles";
import { dropsRole } from "stores/dropsRole";
import styled, { css } from "styled-components";

type ReadReceiptProps = {
  readReceipts: string[] | undefined;
  entryUuid: string;
  isNew: boolean;
};

function makeReadReceiptText(
  readReceipts: ReadReceiptProps["readReceipts"],
  currentRoleAbbreviation: string,
) {
  if (!readReceipts?.length) {
    return "Ikke lest av noen";
  }

  if (readReceipts.length === ROLE_TEXTS.length) {
    // More semantic maybe to use Object.keys(UserRole).length
    // but at the moment of writing that has two extra roles
    return "Lest av alle";
  }

  // At least one read receipt, but not everyone
  const readReceiptsExceptCurrent = readReceipts
    .filter((receipt) => receipt !== currentRoleAbbreviation)
    .map((abbr) => abbr.toLocaleUpperCase());

  if (readReceiptsExceptCurrent.length === 0) {
    // Current role is the only receipt
    return "Lest";
  }

  const sortedReceipts = readReceiptsExceptCurrent.toSorted();
  const lastReceipt = sortedReceipts.pop()!; // Modifies the sortedReceipts array, removing the last element

  if (!sortedReceipts.length) {
    // LastReceipt is the only other receipt
    return `Lest av ${lastReceipt}`;
  }

  // More than one other read receipt to list
  return `Lest av ${sortedReceipts.join(", ")} og ${lastReceipt}`;
}

const ReadReceiptIconContainer = styled.div<{
  $readByCurrentRole: boolean;
  $isNew: boolean;
}>`
  grid-area: 3 / 2;
  align-self: center;
  height: 24px;

  & * {
    opacity: 1;
  }

  ${({ $readByCurrentRole, $isNew, theme }) =>
    $readByCurrentRole
      ? css`
          & * {
            fill: ${$isNew ? theme.colorAlarm : theme.colorSuccess};
          }
        `
      : css`
          cursor: pointer;

          & * {
            fill: ${$isNew
              ? theme.colorAlarmTextSecondary
              : theme.colorTextSecondary};
          }

          &:hover * {
            fill: ${$isNew ? theme.colorAlarmTextMain : theme.colorTextMain};
          }

          &:active * {
            fill: ${$isNew ? theme.colorAlarm : theme.colorSuccess};
          }
        `}
`;

const ReadReceiptTextContainer = styled.div<{ $isNew: boolean }>`
  grid-area: 3 / 3;
  align-self: center;
  align-items: center;

  color: ${({ $isNew, theme }) =>
    $isNew ? theme.colorAlarmTextSecondary : "inherit"};
`;

const ReadReceipts: FC<ReadReceiptProps> = ({
  readReceipts,
  entryUuid,
  isNew,
}) => {
  const { role: currentRole } = dropsRole();
  const currentRoleAbbreviation = getAbbreviationForRole(currentRole);

  const readByCurrentRole = !!readReceipts?.includes(currentRoleAbbreviation);

  const postReceipt = usePostReceipt();

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!readByCurrentRole) {
      postReceipt({
        entryUuid,
        role: currentRoleAbbreviation.toLocaleLowerCase(),
      });
    }
  };

  return (
    <>
      <ReadReceiptIconContainer
        $readByCurrentRole={readByCurrentRole}
        $isNew={isNew}
        onClick={onClick}
      >
        {readReceipts?.length ? <UserCircleCheck /> : <UserCircleDashed />}
      </ReadReceiptIconContainer>
      <ReadReceiptTextContainer $isNew={isNew}>
        <Text
          variant="xs"
          style={{
            fontSize: "14px", // XXS doesn't actually exist in spor-react
            lineHeight: "18px", // XXS doesn't actually exist in spor-react
          }}
        >
          {makeReadReceiptText(readReceipts, currentRoleAbbreviation)}
        </Text>
      </ReadReceiptTextContainer>
    </>
  );
};

export { ReadReceipts };
