import {
  isFailureRequest,
  isSuccessfulRequest,
  RequestStatus,
} from "api/http/httpRequest";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { ISelectListOption } from "shared/components/SelectList/SelectList";
import {
  AlternativeTransportVehicle,
  GetReserveAlternativeTransport,
} from "shared/types/alternativeTransport";
import { getPrintableFromVehicleType } from "shared/utils/alternativeTransport";

export const isAvailableFilter = (
  reserveAlternativeTransport: GetReserveAlternativeTransport,
) => reserveAlternativeTransport.isAvailable;

export const mapReserveAlternativeTransportToOption = ({
  id,
  baseStopName,
  type,
  shiftStartTime,
  shiftEndTime,
  operatorName,
  workShiftId,
  driverPhoneNumber,
  logs,
  isAvailable,
}: GetReserveAlternativeTransport): ISelectListOption => ({
  value: id,
  text: `${baseStopName}, ${getPrintableFromVehicleType(type)}, ${
    shiftStartTime
      ? format(new Date(shiftStartTime), "HH:mm", { locale: nb })
      : "--:--"
  }-${
    shiftEndTime
      ? format(new Date(shiftEndTime), "HH:mm", { locale: nb })
      : "--:--"
  }${workShiftId ? `, ${workShiftId}` : ""}`,
  additionalText: `${operatorName}${
    driverPhoneNumber ? ` (${driverPhoneNumber})` : ""
  }`,

  logs: logs?.map((log) => log.message ?? "") ?? [],

  workShiftId: `${
    shiftStartTime
      ? format(new Date(shiftStartTime), "HH:mm", { locale: nb })
      : "--:--"
  }-${
    shiftEndTime
      ? format(new Date(shiftEndTime), "HH:mm", { locale: nb })
      : "--:--"
  }${workShiftId ? `, ${workShiftId}` : ""}`,
  operatorName: operatorName ?? "Ukjent operatør",
  headingInfo: `${baseStopName}, ${getPrintableFromVehicleType(type)}`,
  isAvailable,
});

const UNKNOWN_OPERATOR = "ukjent operatør";
const UNKNOWN_PHONE = "ukjent telefonnummer";
const UNKNOWN_WORK_SHIFT = "ukjent skiftnr";

export const getDetailedReplacementLogMessage = (
  bookRequestStatus: RequestStatus<any>,
  updateVehicleRequestStatus: RequestStatus<any>,
  vehicle: AlternativeTransportVehicle,
  selectedReserve: GetReserveAlternativeTransport,
): string | null => {
  const { operatorName, driverPhoneNumber } = vehicle;

  const oldOperatorName = operatorName ?? UNKNOWN_OPERATOR;
  const oldPhoneNumber = driverPhoneNumber ?? UNKNOWN_PHONE;

  const {
    operatorName: reserveOperator,
    driverPhoneNumber: reserveDriverPhoneNumber,
    workShiftId: reserveWorkShiftId,
  } = selectedReserve;

  const newOperatorName = reserveOperator ?? UNKNOWN_OPERATOR;
  const newPhoneNumber = reserveDriverPhoneNumber ?? UNKNOWN_PHONE;
  const newWorkShifId = reserveWorkShiftId ?? UNKNOWN_WORK_SHIFT;

  if (
    isSuccessfulRequest(bookRequestStatus) &&
    isSuccessfulRequest(updateVehicleRequestStatus)
  ) {
    return (
      `Orginalturen med operatør ${oldOperatorName} og tlf.nr ${oldPhoneNumber} ble erstattet` +
      ` av reservekjøretøy med operatør ${newOperatorName}, tlf.nr ${newPhoneNumber} og skiftnr ${newWorkShifId}.`
    );
  }

  if (
    isSuccessfulRequest(bookRequestStatus) &&
    isFailureRequest(updateVehicleRequestStatus)
  ) {
    return (
      `Reservekjøretøyet med operatør ${newOperatorName} og tlf.nr. ${newPhoneNumber}` +
      ` ble booket, men mislyktes å oppdatere orginalturen med operatørnavn ${oldOperatorName}` +
      ` og tlf.nr. ${newPhoneNumber}.`
    );
  }

  if (
    isFailureRequest(bookRequestStatus) &&
    isSuccessfulRequest(updateVehicleRequestStatus)
  ) {
    return (
      `Orginalturen med operatør ${oldOperatorName} og tlf.nr ${oldPhoneNumber} ble erstattet` +
      ` av reservekjøretøy med operatør ${newOperatorName} og tlf.nr ${newPhoneNumber}, men` +
      ` bookingen av reservekjøretøyet mislyktes.`
    );
  }

  if (
    isFailureRequest(bookRequestStatus) &&
    isSuccessfulRequest(updateVehicleRequestStatus)
  ) {
    return (
      `Originalturen med operatør ${oldOperatorName} og tlf.nr ${oldPhoneNumber} ble ikke oppdatert` +
      ` og bookingen av av reservekjøretøyet med operatør ${newOperatorName} og tlf.nr` +
      ` ${newPhoneNumber} mislyktes.`
    );
  }
  return null;
};

export const getLogMessageForReserveVehicle = (
  vehicle: AlternativeTransportVehicle,
): string =>
  `Kjøretøyet settes inn som reserve for skiftnr ${
    vehicle.workShiftPlan?.shiftId
  }. Turen går fra ${
    vehicle.originStopName ?? "uspesifisert stoppested"
  } til ${vehicle.destinationStopName ?? "uspesifisert stoppested"}, fra kl ${
    vehicle.scheduledDepartureTimeOrigin
      ? format(new Date(vehicle.scheduledDepartureTimeOrigin), "HH:mm", {
          locale: nb,
        })
      : "--:--"
  } til ${
    vehicle.scheduledArrivalTimeDestination
      ? format(new Date(vehicle.scheduledArrivalTimeDestination), "HH:mm", {
          locale: nb,
        })
      : "--:--"
  }.`;
