import { useEffect, useState } from "react";
import {
  BaseGpsData,
  BaseGpsResponseSchema,
  BusGpsData,
  TrainGpsData,
} from "./types";

export const clamp = (min: number, max: number, value: number) =>
  Math.min(Math.max(value, min), max);

export const addClosestNeighbor = (
  oldData: BusGpsData[] | TrainGpsData[],
  newData: BaseGpsData[],
) => {
  const closestIndexes = oldData.map((it) =>
    it.closestNeighbor
      ? oldData.findIndex(
          (vehicle) => vehicle.closestNeighbor === it.closestNeighbor,
        )
      : null,
  );
  newData.forEach((it, index) => {
    const closestIndex = closestIndexes[index];
    if (!closestIndex) return;
    it.closestNeighbor = newData[closestIndex];
  });
};

export const busGpsDataToBaseGpsData = (data: BusGpsData[]) => {
  const baseGps = BaseGpsResponseSchema.parse({
    data:
      data.map((it) => ({
        id: [it.workshiftId, it.trainNumber].filter(Boolean).join(" - "),
        phoneNumber: it.phoneNumber,
        timestamp: it.timestamp,
        gps: it.gps,
        closestNeighbor: null,
        path: it.path,
      })) ?? [],
  });
  addClosestNeighbor(data, baseGps.data);
  return baseGps.data;
};

export const trainGpsDataToBaseGpsData = (data: TrainGpsData[]) => {
  const baseGps = BaseGpsResponseSchema.parse({
    data:
      data.map((it) => ({
        id: it.resourceId,
        phoneNumber: null,
        timestamp: it.timestamp,
        gps: it.gps,
        closestNeighbor: null,
        path: null,
      })) ?? [],
  });
  addClosestNeighbor(data, baseGps.data);
  return baseGps.data;
};

export const useOnlineStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline;
};

const fadeOutAndDelete = (child: HTMLElement) => {
  // Set initial style for fading out
  child.style.transition = "opacity 0.5s ease";
  child.style.opacity = "0";

  // Remove the element after the fade-out duration
  setTimeout(() => {
    child.parentElement?.removeChild(child);
  }, 500);
};

export const clearAllToasts = () => {
  const toastManager = document.getElementById("chakra-toast-manager-bottom");
  if (!toastManager) return;
  const children = Array.from(toastManager.children);
  children.forEach((child) => {
    if (child instanceof HTMLElement) {
      fadeOutAndDelete(child);
    }
  });
};
