import { LinkOverlay } from "@chakra-ui/react";
import { HStack } from "@vygruppen/spor-react";
import { format, isToday } from "date-fns";
import { nb } from "date-fns/locale";
import { DateSpan } from "features/VehicleList/common/DateSpan";
import ElementWrapper from "features/VehicleList/common/ElementWrapper";
import { FC } from "react";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { CallButton } from "shared/components/buttons/CallButton";
import { Text } from "shared/components/typography/TitleStyles";
import { ReserveAlternativeTransport } from "shared/types/alternativeTransport";
import { getPrintableFromVehicleType } from "shared/utils/alternativeTransport";
import { RESERVEBUS } from "shared/utils/routes";
import { useTheme } from "styled-components";

type Props = {
  reserveAlternativeTransport: ReserveAlternativeTransport;
  elementSelected: boolean;
};

export const ReserveAlternativeTransportElement: FC<Props> = ({
  reserveAlternativeTransport,
  elementSelected,
}) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const {
    workShiftId,
    shiftStartTime,
    shiftEndTime,
    driverPhoneNumber,
    isAvailable,
    baseStopName,
    type,
  } = reserveAlternativeTransport;

  const formattedShiftStartTime = shiftStartTime
    ? format(new Date(shiftStartTime), "HH:mm", { locale: nb })
    : "";

  const formattedShiftEndTime = shiftEndTime
    ? format(new Date(shiftEndTime), "HH:mm", { locale: nb })
    : "";

  const isNotToday = shiftStartTime ? !isToday(shiftStartTime) : false;

  const hasPhoneNumber = !!driverPhoneNumber && driverPhoneNumber.length > 0;

  /*
    This is a workaround. If we send in the plus to Puzzel, it is stripped away
    before the 18740 prefix is added. This works fine if you're an admin, but
    not for normal DROPS users. But if we replace the + with 00, it is not stripped,
    and with the 00 it works just fine. Don't ask me why.
   
    These formats work for everyone:
    18740 0047 12345678 <- Result from what we send in with the workaround
    18740 12345678
    0047 12345678
    12345678

    This format only works for admins:
    18740 47 12345678 <- Result from what we send in without the workaround
  */
  const phoneNumberWithDigitsOnly = driverPhoneNumber
    ? driverPhoneNumber.replace("+", "00")
    : null;

  return (
    <ElementWrapper selected={elementSelected}>
      <LinkOverlay
        as={Link}
        display="flex"
        flexDir="column"
        gap={0.5}
        flex={1}
        to={`${generatePath(RESERVEBUS, {
          vehicleUuid: reserveAlternativeTransport.id,
        })}?${searchParams.toString()}`}
        _focusVisible={{
          outline: `2px solid ${theme.__focusBorderColor}`,
          borderRadius: "6px",
        }}
      >
        <HStack height="25px" gap={1}>
          <Text bold>{`Reserve ${workShiftId ? `${workShiftId}` : ""}`}</Text>
          {!isAvailable && <DropsBadge semantics="info">I bruk</DropsBadge>}
        </HStack>

        <Text>
          {`${baseStopName}, ${getPrintableFromVehicleType(type) ?? ""}`}
        </Text>
        <Text>
          {formattedShiftStartTime} - {formattedShiftEndTime}
          {isNotToday && shiftStartTime && (
            <DateSpan>({format(shiftStartTime, "dd.MM.yy")})</DateSpan>
          )}
        </Text>
      </LinkOverlay>

      {hasPhoneNumber && (
        <CallButton phoneNumber={phoneNumberWithDigitsOnly!} size="xs" />
      )}
    </ElementWrapper>
  );
};
