import styled from "styled-components";
import { useSelect } from "downshift";
import {
  DropDownBtn,
  SelectListContainer,
  SelectListLITag,
  SelectListULTag,
} from "./SelectListStyle";
import { ArrowIcon } from "../../icons/ArrowIcon";
import { Text } from "../typography/TitleStyles";

export interface ISelectListOption {
  value: string;
  text: string;
  additionalText?: string;
  logs?: string[];
  workShiftId?: string;
  operatorName?: string;
  headingInfo?: string;
  isAvailable?: boolean;
}

interface ISelectList {
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  options: ISelectListOption[];
  selected: string;
  onChange: (value: string) => void;
  width?: string;
  maxDropDownHeight?: string;
  hiddenAndDisabledDefault?: boolean;
}

const SelectListText = styled(Text)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
`;

const AdditionalText = styled(Text)`
  display: inline-block;
  padding-left: 0.5rem;
  color: inherit;
`;

const SelectList = ({
  options,
  selected,
  onChange,
  width,
  maxDropDownHeight,
  hiddenAndDisabledDefault = true,
}: ISelectList) => {
  const optionValues = options.map((it) => it.value);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: optionValues,
    initialSelectedItem: selected,
    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem || "");
    },
  });

  const getText = (item: string) => {
    const found = options.find((it) => it.value === item);
    return found && found.text;
  };

  const getAdditionalText = (item: string) => {
    const found = options.find((it) => it.value === item);
    return found && found.additionalText;
  };

  return (
    <SelectListContainer style={{ width }}>
      <DropDownBtn
        $isActive={isOpen}
        style={{ width }}
        type="button"
        {...getToggleButtonProps()}
      >
        <SelectListText> {getText(selected)} </SelectListText>
        <ArrowIcon rotate={isOpen ? -180 : 0} />
      </DropDownBtn>
      <SelectListULTag
        $width={width}
        $maxDropDownHeight={maxDropDownHeight}
        {...getMenuProps()}
      >
        {isOpen &&
          optionValues.map((item: any, index: any) => (
            <SelectListLITag
              hidden={hiddenAndDisabledDefault && item === ""}
              $disabled={hiddenAndDisabledDefault && item === ""}
              $shouldHighlight={highlightedIndex === index}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {getAdditionalText(item) ||
              getAdditionalText(item) !== undefined ? (
                <>
                  <Text bold style={{ color: "inherit" }}>
                    {getText(item)}
                  </Text>
                  <AdditionalText>{getAdditionalText(item)}</AdditionalText>
                </>
              ) : (
                getText(item)
              )}
            </SelectListLITag>
          ))}
      </SelectListULTag>
      {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div tabIndex={0} />
    </SelectListContainer>
  );
};

export default SelectList;
