import { Button, Heading, LightSpinner, TextLink } from "@vygruppen/spor-react";
import { LoginContainer } from "app/Auth/Login/LoginStyle";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoginStatus } from "./AuthBoundary";
import { checkUserPermissions, resetSession } from "./auth";

function createBodyText(errorMessage?: string): string {
  const errorText = errorMessage ? JSON.stringify(errorMessage) : "Not found";
  const text =
    `Hei Team Brukerflater,` +
    `\n\n` +
    `(Legg inn dine detaljer her)` +
    `\n\n` +
    `-- Tekniske detaljer ---` +
    `\n\n` +
    `${errorText}`;
  return encodeURIComponent(text);
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    // Null stops interval
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

enum RetryStatus {
  IDLE,
  COUNTDOWN,
  ACTIVE,
  FAILED,
}

type RetryState = {
  status: RetryStatus;
  attemptNumber: number;
  cooldownRemaining: number;
};

const INITIAL_COUNTDOWN = 5;

function retryText(state: RetryState): string {
  switch (state.status) {
    case RetryStatus.IDLE:
      return "...";
    case RetryStatus.COUNTDOWN:
      return `Prøver igjen om ${state.cooldownRemaining} sekunder... Forsøk ${state.attemptNumber}/5`;
    case RetryStatus.ACTIVE:
      return `Prøver igjen... Forsøk ${state.attemptNumber}/5`;
    case RetryStatus.FAILED:
      return "Beklager";
    default:
      return "";
  }
}

const NetworkError = ({
  updateLoginStatus,
  message,
}: {
  updateLoginStatus: (status: LoginStatus) => void;
  message?: string;
}) => {
  const [retryState, setRetryState] = useState<RetryState>({
    status: RetryStatus.COUNTDOWN,
    attemptNumber: 1,
    cooldownRemaining: INITIAL_COUNTDOWN,
  });

  const callCheckUserPermissions = useCallback(async () => {
    try {
      const result = await checkUserPermissions();
      if (result.permission) {
        updateLoginStatus({ state: "Success" });
      } else {
        setRetryState({
          status: RetryStatus.IDLE,
          attemptNumber: retryState.attemptNumber + 1,
          cooldownRemaining:
            INITIAL_COUNTDOWN + retryState.attemptNumber * INITIAL_COUNTDOWN,
        });
      }
    } catch {
      setRetryState({
        status: RetryStatus.IDLE,
        attemptNumber: retryState.attemptNumber + 1,
        cooldownRemaining: INITIAL_COUNTDOWN,
      });
    }
  }, [retryState]);

  useInterval(
    () => {
      if (retryState.cooldownRemaining > 0) {
        setRetryState({
          ...retryState,
          cooldownRemaining: retryState.cooldownRemaining - 1,
        });
      } else {
        setRetryState({ ...retryState, status: RetryStatus.ACTIVE });
      }
    },
    retryState.status === RetryStatus.COUNTDOWN ? 1000 : null,
  );

  useEffect(() => {
    switch (retryState.status) {
      case RetryStatus.IDLE:
        if (retryState.attemptNumber < 6) {
          setRetryState({ ...retryState, status: RetryStatus.COUNTDOWN });
        } else {
          setRetryState({ ...retryState, status: RetryStatus.FAILED });
        }
        break;
      case RetryStatus.COUNTDOWN:
        break;
      case RetryStatus.ACTIVE:
        callCheckUserPermissions();
        break;
      case RetryStatus.FAILED:
        // Tell user we couldn't get it done
        break;
      default:
        break;
    }
  }, [retryState]);

  return (
    <LoginContainer style={{ flexDirection: "column", textAlign: "center" }}>
      <>
        <Heading as="h1">Får ikke kontakt med tjener</Heading>
        <br />
        <Heading as="h2" variant="md">
          Drops Dashbord får ikke kontakt med tjener. Dette kan skyldes
          nettverksproblemer.
        </Heading>
        <Heading as="h2" variant="md">
          Vennligst ta kontakt med IT
          <TextLink
            href={`mailto:team-brukerflater-drops@vy.no?cc=itservicesenter@service-now.com&subject=Nettverksfeil i Dashboard&body=${createBodyText(message)}`}
            target="_blank"
            rel="noopener noreferer"
            style={{ margin: "0 0.5ch", display: "inline" }}
          >
            her.
          </TextLink>
        </Heading>
        <br />
        <br />
      </>
      {retryState.status !== RetryStatus.FAILED ? (
        <LightSpinner width="58px">{retryText(retryState)}</LightSpinner>
      ) : (
        <>
          <Heading as="h2" variant="md">
            Beklager, men vi lyktes ikke med å koble til. Vennligst logg ut og
            prøv igjen.
          </Heading>
          <Button
            onClick={() => {
              resetSession();
              updateLoginStatus({ state: "RefreshFailed" });
            }}
            mt={5}
            size="lg"
            width="180px"
          >
            Logg ut
          </Button>
        </>
      )}
    </LoginContainer>
  );
};

export default NetworkError;
