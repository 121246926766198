import { zodResolver } from "@hookform/resolvers/zod";
import {
  GlobalEvent,
  GlobalFormSchema,
  globalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InfrastructureEventCreatedResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { formToGlobalInformationRequest } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import { getDefaultValues } from "features/CenterContent/RoleContent/GlobalEvents/globalEventDefaultValues";
import { GlobalEventModalContent } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModalContent";
import { usePostGlobalInfo } from "features/CenterContent/RoleContent/GlobalEvents/usePostGlobalInfo";
import { GlobalEventWarningText } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventWarningText";
import { Text } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { VisibilityInput } from "features/CenterContent/RoleContent/GlobalEvents/VisibilityInput";

type GlobalEventModalProps = {
  onClose: () => void;
  event?: GlobalEvent;
  relatedInfrastructureEvent?: InfrastructureEventCreatedResponse;
};

export const GlobalEventModal: FC<GlobalEventModalProps> = ({
  onClose,
  event,
  relatedInfrastructureEvent,
}) => {
  const isEditMode = !!event;
  const isRelatedToIncident =
    !!relatedInfrastructureEvent ||
    (isEditMode && event?.referencedIncidentIds.length > 0);
  const incidentId =
    isEditMode && event?.referencedIncidentIds.length > 0
      ? event.referencedIncidentIds[0]
      : relatedInfrastructureEvent?.incidentId;

  const formMethods = useForm<GlobalFormSchema>({
    resolver: zodResolver(globalFormSchema),
    defaultValues: getDefaultValues(relatedInfrastructureEvent, event),
    mode: "onBlur",
  });

  const { mutate: postGlobalInfo, status: postGlobalInfoStatus } =
    usePostGlobalInfo(event?.uuid);
  return (
    <ActionModal
      title={`${isEditMode ? "Endre" : "Opprett"} global driftsmelding`}
      actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
      titleBadge={isRelatedToIncident ? "Infrastrukturavhengig" : "Uavhengig"}
      onClose={onClose}
      onSubmit={formMethods.handleSubmit((data) =>
        postGlobalInfo(
          formToGlobalInformationRequest(data.globalForm, incidentId),
        ),
      )}
      isLoading={postGlobalInfoStatus === "pending"}
      isSuccess={postGlobalInfoStatus === "success"}
      isError={postGlobalInfoStatus === "error"}
      successMessage={isEditMode ? "Hendelse endret" : "Hendelse opprettet"}
      failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelsen. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
      internalMessage={isEditMode ? "edit" : "create"}
    >
      {isEditMode && (
        <Text variant="xs" width="100%" paddingY={2}>
          <span style={{ fontWeight: "bold" }}>Sist oppdatert:</span>{" "}
          {format(event.updatedAt.toDate(), "dd.MM.yyyy")} kl.{" "}
          {format(event.updatedAt.toDate(), "HH:mm")}
        </Text>
      )}
      <GlobalEventModalContent formMethods={formMethods} />
      <VisibilityInput
        formMethods={formMethods}
        isRelatedToIncident={isRelatedToIncident}
      />
      <GlobalEventWarningText />
    </ActionModal>
  );
};
