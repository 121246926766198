import React, { FC, useState } from "react";
import { StaticInfoMessage } from "shared/components/feedback/InfoMessage/StaticInfoMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ISelectListOption } from "shared/components/SelectList/SelectList";
import { GetReserveAlternativeTransportResponse } from "shared/types/alternativeTransport";
import { sortReserveTransport } from "shared/utils/alternativeTransport";
import { mapReserveAlternativeTransportToOption } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/VehicleReplacement/utils";
import {
  ChoiceChip,
  HStack,
  SearchInput,
  Stack,
  Text,
} from "@vygruppen/spor-react";
import styled from "styled-components";
import {
  CheckmarkOutline18Icon,
  CloseOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { DropsExpendableAlert } from "./DropsExpandableAlert";

interface HoverChoiceChipProps {
  isChecked: boolean;
}

export const filterLogsAfterLastUse = (logs: string[]) => {
  const lastFristiltIndex = logs.lastIndexOf("Kjøretøyet er fristilt");
  if (lastFristiltIndex === -1) {
    return logs;
  }
  return logs.slice(lastFristiltIndex + 1);
};

export const filterLogsDuplicates = (logs: string[]) =>
  logs.filter((log, index) => {
    if (log === "Kjøretøyet er tatt i bruk") {
      const nextLog = logs[index + 1];
      if (nextLog && nextLog !== "Kjøretøyet er tatt i bruk") {
        return false;
      }
    }
    return true;
  });

export const CustomReserveVehicleModalList = styled.div<HoverChoiceChipProps>`
  width: 100%;
  padding: 0 25px 10px;
  cursor: pointer;
  border-bottom: 1px dashed ${({ theme }) => theme.colorDivider};
  position: relative;
  background-color: ${({ theme, isChecked }) =>
    isChecked ? theme.colorSuccessFill : ""};

  &:hover {
    background-color: ${({ theme, isChecked }) =>
      isChecked ? theme.colorSuccessFill : theme.colorHoverItem};

    .choice-chip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const HoverChoiceChip = styled(ChoiceChip)<HoverChoiceChipProps>`
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  visibility: ${({ isChecked }) => (isChecked ? "visible" : "hidden")};
  padding-top: 0.5rem;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
`;

type Props = {
  reserveVehicles: GetReserveAlternativeTransportResponse | undefined;
  isReserveLoading: boolean;
  isReserveError: boolean;
  selectedReserveId: string;
  setSelectedReserveId: (reserveId: string) => void;
};

export const SelectReserveVehicleDropdown: FC<Props> = ({
  reserveVehicles,
  isReserveLoading,
  isReserveError,
  selectedReserveId,
  setSelectedReserveId,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const reserveVehicleOptions: ISelectListOption[] =
    reserveVehicles?.vehicles
      ?.sort(sortReserveTransport)
      ?.map(mapReserveAlternativeTransportToOption) ?? [];

  const filteredOptions = reserveVehicleOptions.filter((option) => {
    const query = searchQuery.toLowerCase();
    return (
      option?.headingInfo?.toLowerCase().includes(query) ||
      option?.additionalText?.toLowerCase().includes(query) ||
      option?.workShiftId?.toLowerCase().includes(query) ||
      option?.operatorName?.toLowerCase().includes(query)
    );
  });
  const handleItemClick = (value: string) => {
    setSelectedReserveId(value === selectedReserveId ? "" : value);
  };

  if (isReserveLoading) {
    return <SkeletonLoader skeletonType="selectReserveDropdown" />;
  }

  if (isReserveError) {
    return (
      <StaticInfoMessage
        severity="error"
        summary="Kunne ikke hente tilgjengelige reservebusser. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
        style={{ boxShadow: "none" }}
      />
    );
  }

  const getShiftNumber = (logEntry: string): string => {
    const match = logEntry.match(/skiftnr\s+\d+(?:-\d+)?/i);
    if (match && match[0]) {
      return `Brukes for ${match[0]}`;
    }
    return logEntry;
  };

  return (
    <Stack alignItems="center" spacing={0}>
      {reserveVehicleOptions.length > 10 && (
        <Stack width="calc(100% - 25px)" spacing={0}>
          <Text variant="sm" fontWeight="bold" align="start">
            Velg reservekjøretøy:
          </Text>
          <Text variant="sm" pb={2} pt={2}>
            Søk på tognr, stasjon, linjer eller sett-ID
          </Text>
          <Stack pb={2}>
            <SearchInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
        </Stack>
      )}

      {filteredOptions.map((option) => {
        const isSelected = selectedReserveId === option.value;

        return (
          <CustomReserveVehicleModalList
            key={option.value}
            isChecked={isSelected}
          >
            <HStack display="flex" justifyContent="space-between">
              <Text variant="sm" fontWeight="bold">
                {option.headingInfo}
              </Text>
              <HoverChoiceChip
                className="choice-chip"
                isChecked={isSelected}
                icon={{
                  default: <CheckmarkOutline18Icon />,
                  checked: <CloseOutline18Icon />,
                }}
                onChange={() => handleItemClick(option.value)}
              >
                {isSelected ? ` Fjern valg` : "Velg"}
              </HoverChoiceChip>
            </HStack>
            <Text variant="sm">{option.workShiftId}</Text>
            <Text variant="sm">{option.operatorName}</Text>
            <Text variant="sm">{option.additionalText}</Text>

            {option.logs &&
              option.logs.length > 0 &&
              filterLogsAfterLastUse(filterLogsDuplicates(option.logs)).map(
                (logEntry, index) => (
                  <DropsExpendableAlert
                    mt={1}
                    key={index}
                    title={getShiftNumber(logEntry)}
                    variant="warning"
                  >
                    <Text>{logEntry}</Text>
                  </DropsExpendableAlert>
                ),
              )}
          </CustomReserveVehicleModalList>
        );
      })}
    </Stack>
  );
};
