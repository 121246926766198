import { zodResolver } from "@hookform/resolvers/zod";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import {
  formSchema as infraFormSchema,
  FormSchema as InfraFormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { getInfrastructureEventBody } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import { usePostInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/usePostInfrastructureEvent";
import {
  getDefaultValuesCreate,
  getDefaultValuesEdit,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/infrastructureEventDefaultValues";
import { getDefaultValues as getGlobalDefaultValues } from "features/CenterContent/RoleContent/GlobalEvents/globalEventDefaultValues";
import { InfrastructureModalContent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureModalContent";
import { MajorEventWarning } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/MajorEventWarning";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { isInfrastructureStretchClosedEvent } from "shared/utils/infrastructureEvent";
import { useOpenEditFields } from "stores/useOpenEditFields";
import { useStretchBuilder } from "stores/useStretchBuilder";
import {
  globalFormSchema,
  GlobalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { usePostGlobalInfo } from "features/CenterContent/RoleContent/GlobalEvents/usePostGlobalInfo";
import { formToGlobalInformationRequest } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import { EditGlobalEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/EditGlobalEvent";
import { Stack, StaticAlert, Text } from "@vygruppen/spor-react";

const FIELDS_AFFECTING_TEXT = [
  "stretchName",
  "reason",
  "action",
  "consequence",
  "prognosisType",
  "prognosisTime",
];

export const InfrastructureEvent: FC = () => {
  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    stretchBuilderModalState,
    closeAndResetStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.stretchBuilderModalState,
    state.closeAndResetStretchBuilder,
  ]);

  const [editGlobalEvent, setEditGlobalEvent] = useState(false);

  const event =
    (stretchBuilderModalState.type === "infrastructure" &&
      stretchBuilderModalState.eventInfo) ||
    undefined;

  const isEditMode = !!event;

  const infrastructureDefaultValues = isEditMode
    ? getDefaultValuesEdit(event)
    : getDefaultValuesCreate();

  const infrastructureFormMethods = useForm<InfraFormSchema>({
    resolver: zodResolver(infraFormSchema),
    defaultValues: infrastructureDefaultValues,
  });

  const globalFormMethods = useForm<GlobalFormSchema>({
    resolver: zodResolver(globalFormSchema),
    defaultValues: getGlobalDefaultValues(
      undefined,
      event?.relatedGlobalInformation ?? undefined,
    ),
  });

  const {
    mutate: postInfraInfo,
    isPending: isPostInfrastructureRequestPending,
    isSuccess: isPostInfrastructureRequestSuccess,
    isError: isPostInfrastructureRequestError,
    data: postInfrastructureRequestData,
  } = usePostInfrastructureEvent(event?.uuid);

  const {
    mutate: postGlobalInfo,
    isPending: isPostGlobalRequestPending,
    isSuccess: isPostGlobalRequestSuccess,
    isError: isPostGlobalRequestError,
  } = usePostGlobalInfo(event?.relatedGlobalInformation?.uuid);

  const [isMajorEvent, setIsMajorEvent] = useState(false);

  const {
    handleSubmit: handleInfrastructureSubmit,
    trigger: triggerInfrastructureValidation,
  } = infrastructureFormMethods;

  const { handleSubmit: handleGlobalSubmit, trigger: triggerGlobalValidation } =
    globalFormMethods;

  const openEditFields = useOpenEditFields((state) => state.openEditFields);

  const { enabled: globalEventsEnabled } = useFeatureFlag("gobalEvents");

  const legsIncludeStop = (
    stopId: string,
    legs: { fromStop: string; toStop: string }[],
  ) => legs.some((leg) => leg.fromStop === stopId || leg.toStop === stopId);

  const onSubmit = handleInfrastructureSubmit((data) => {
    const requestBody = getInfrastructureEventBody(data.infrastructureForm);
    postInfraInfo(requestBody, {
      onSuccess: () => {
        const form = data.infrastructureForm;
        const osloSIsClosed =
          legsIncludeStop("OSL", form.affectedLegs) &&
          isInfrastructureStretchClosedEvent(form.event);
        const minimumThirtyMinutes =
          form.durations.type === "OPEN_ENDED" &&
          Number(form.durations.extension_time_in_minutes) >= 30;

        // TODO: Foreløpige kriterier. Justeres etter avklaring med Arne og Tine
        if (
          osloSIsClosed &&
          minimumThirtyMinutes &&
          !isEditMode &&
          globalEventsEnabled
        ) {
          setIsMajorEvent(true);
        }
      },
    });
  });

  const onGlobalEventSubmit = handleGlobalSubmit((globalFormData) =>
    postGlobalInfo(
      formToGlobalInformationRequest(
        globalFormData.globalForm,
        event?.incidentId ?? undefined,
      ),
    ),
  );

  if (stretchBuilderModalState.type !== "infrastructure") return null;

  if (isMajorEvent && isPostInfrastructureRequestSuccess) {
    return (
      <MajorEventWarning
        response={postInfrastructureRequestData}
        onClose={closeAndResetStretchBuilder}
      />
    );
  }

  const changedInfrastructureFields =
    infrastructureFormMethods.formState.dirtyFields.infrastructureForm;
  const changedFieldNames = changedInfrastructureFields
    ? Object.keys(changedInfrastructureFields)
    : [];

  const isChangedText = changedFieldNames.some((field) =>
    FIELDS_AFFECTING_TEXT.includes(field),
  );

  return (
    <FormProvider {...infrastructureFormMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <ActionModal
          title={`${isEditMode ? "Endre" : "Opprett"} infrastrukturhendelse`}
          actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
          onClose={() => {
            if (!isMajorEvent) closeAndResetStretchBuilder();
          }}
          onSubmit={async () => {
            const globalIsValid = await triggerGlobalValidation();
            const infraIsValid = await triggerInfrastructureValidation();
            if (
              isEditMode &&
              !!event.relatedGlobalInformation &&
              editGlobalEvent
            ) {
              if (infraIsValid && globalIsValid) {
                onGlobalEventSubmit();
                onSubmit();
              }
            } else if (infraIsValid) {
              onSubmit();
            }
          }}
          isLoading={
            isPostInfrastructureRequestPending || isPostGlobalRequestPending
          }
          isSuccess={
            isPostInfrastructureRequestSuccess &&
            (event?.relatedGlobalInformation && editGlobalEvent
              ? isPostGlobalRequestSuccess
              : true)
          }
          isError={isPostInfrastructureRequestError || isPostGlobalRequestError}
          isSubmitDisabled={openEditFields.length > 0}
          successMessage={`Hendelse ${isEditMode ? "endret" : "opprettet"}`}
          failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
          internalMessage={isEditMode ? "edit" : "create"}
        >
          <Stack gap={4} width="100%">
            <InfrastructureModalContent
              stretchTitle={
                clickedStops.length < 1 && isEditMode
                  ? event.stretchName
                  : getStretchName(
                      selectedLegs,
                      clickedStops,
                      allStopsSelected,
                    ).join(", ")
              }
            />
            {event?.relatedGlobalInformation && (
              <>
                {isChangedText && (
                  <StaticAlert variant="warning" title="OBS!">
                    <Text>
                      Du har endret infrastrukturhendelsen på en måte som
                      kanskje påvirker den globale meldingen
                    </Text>
                  </StaticAlert>
                )}
                <EditGlobalEvent
                  globalEventFormMethods={globalFormMethods}
                  editGlobalEvent={editGlobalEvent}
                  setEditGlobalEvent={setEditGlobalEvent}
                />
              </>
            )}
          </Stack>
        </ActionModal>
      ) : (
        <div />
      )}
    </FormProvider>
  );
};
